@charset "UTF-8";
.icv {
  position: relative;
  overflow: hidden;
  cursor: row-resize; }

.icv__icv--vertical {
  cursor: row-resize; }

.icv__icv--horizontal {
  cursor: col-resize; }

.icv__img {
  pointer-events: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: none;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  top: 0;
  display: block; }

.icv__is--fluid .icv__img {
  display: none; }

.icv__img-a {
  height: auto;
  position: static;
  z-index: 1;
  left: 0px; }

.icv__img-b {
  height: 100%;
  position: absolute;
  z-index: 2;
  left: auto;
  right: 0px;
  width: auto; }

.icv__icv--vertical .icv__img-b {
  width: 100%;
  height: auto; }

.icv__imposter {
  z-index: 4;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%; }

.icv__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 3; }

.icv__is--fluid .icv__wrapper, .icv__icv--vertical .icv__wrapper {
  width: 100% !important; }

.icv__is--fluid .icv__wrapper, .icv__icv--horizontal .icv__wrapper {
  height: 100% !important; }

.icv__fluidwrapper {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.icv__control {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  top: 0px;
  z-index: 5; }

.icv__icv--vertical .icv__control {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  left: 0;
  width: 100%; }

.icv__control-line {
  height: 50%;
  width: 2px;
  z-index: 6; }

.icv__icv--vertical .icv__control-line {
  width: 50%; }

.icv__theme-wrapper {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  -webkit-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s;
  z-index: 5; }

.icv__icv--vertical .icv__theme-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.icv__arrow-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s; }

.icv__arrow-a {
  -webkit-transform: scale(1.5) rotateZ(180deg);
  transform: scale(1.5) rotateZ(180deg);
  height: 20px;
  width: 20px;
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.33));
  filter: drop-shadow(0px -3px 5px rgba(0, 0, 0, 0.33)); }

.icv__arrow-b {
  -webkit-transform: scale(1.5) rotateZ(0deg);
  transform: scale(1.5) rotateZ(0deg);
  height: 20px;
  width: 20px;
  -webkit-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.33));
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.33)); }

.icv__circle {
  width: 50px;
  height: 50px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 999px; }

.icv__label {
  position: absolute;
  bottom: 1rem;
  z-index: 12;
  background: rgba(0, 0, 0, 0.33);
  color: white;
  border-radius: 3px;
  padding: 0.5rem 0.75rem;
  font-size: 0.85rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.icv__label.vertical {
  bottom: auto;
  left: 1rem; }

.icv__label.on-hover {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.25s cubic-bezier(0.68, 0.26, 0.58, 1.22);
  transition: 0.25s cubic-bezier(0.68, 0.26, 0.58, 1.22); }

.icv:hover .icv__label.on-hover {
  -webkit-transform: scale(1);
  transform: scale(1); }

.icv__label-before {
  left: 1rem; }

.icv__label-after {
  right: 1rem; }

.icv__label-before.vertical {
  top: 1rem; }

.icv__label-after.vertical {
  bottom: 1rem;
  right: auto; }

.icv__body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/*# sourceMappingURL=image-compare-viewer.min.css.map*/
body {
  --font-family-default: "HK Grotesk", Helvetica, Arial, -apple-system, sans-serif;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --spacing-unit: 6px;
  --white: #ffffff;
  --gray-100: #f7f7f7;
  --gray-200: #f0f0f0;
  --gray-300: #e3e3e3;
  --gray-400: #d0d0d0;
  --gray-500: #afafaf;
  --gray-600: #868686;
  --gray-700: #696969;
  --gray-800: #434343;
  --gray-900: #1a1a1a;
  --black: #000000;
  --deepdive-blue-light: rgb(41, 76, 114);
  --deepdive-blue: #182b42;
  --deepdive-blue-dark: #09101d; }

.grid {
  display: grid;
  grid-template-columns: calc(var(--spacing-unit) * 4) repeat(12, 1fr) calc(var(--spacing-unit) * 4);
  column-gap: calc(var(--spacing-unit) * 4); }
  @media (min-width: 1440px) {
    .grid {
      grid-template-columns: 1fr repeat(12, 90px) 1fr; } }
  @media (max-width: 900px) {
    .grid {
      grid-template-columns: calc(var(--spacing-unit) * 5) repeat(12, 1fr) calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .grid {
      grid-template-columns: calc(var(--spacing-unit) * 2) repeat(12, 1fr) calc(var(--spacing-unit) * 2);
      column-gap: calc(var(--spacing-unit) * 1); } }

.figure {
  margin: 0; }
  .figure picture img,
  .figure video {
    width: 100%;
    display: block; }
  .figure .figure__caption {
    margin-top: calc(var(--spacing-unit) * 2);
    max-width: calc(var(--spacing-unit) * 55);
    counter-increment: figures;
    hyphens: manual;
    display: grid;
    grid-template-columns: auto auto 1fr;
    column-gap: calc(var(--spacing-unit) * 1); }
    .figure .figure__caption::before {
      content: counter(figures);
      color: var(--primary-color); }
    .figure .figure__caption .figure__dash {
      color: var(--gray-400); }

.figure--gray-background .figure__image {
  background-color: var(--gray-200);
  padding: 6%; }

.figure--gray-background img {
  mix-blend-mode: multiply; }

.figure--gray-background.figure--no-padding .figure__image {
  padding: 0; }

.icv__label {
  padding: 1px 5px;
  bottom: 0.5em;
  background: none; }

.icv__label-before {
  left: 0.5em; }

.icv__label-after {
  right: 0.5em; }

.link {
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.031em;
  font-weight: 300;
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-decoration-skip-ink: auto;
  text-underline-offset: 8px;
  text-decoration-thickness: 4px;
  position: relative; }
  @media (max-width: 900px) {
    .link {
      font-size: 60px;
      line-height: 67px; } }
  @media (max-width: 599px) {
    .link {
      font-size: 42px;
      line-height: 54px; } }

@media (max-width: 599px) {
  .link {
    text-underline-offset: 5px;
    text-decoration-thickness: 3px; } }

.quote {
  margin: 0;
  hanging-punctuation: first; }
  .quote .quote__text {
    color: var(--primary-color);
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.004em;
    font-weight: 300; }
    @media (max-width: 599px) {
      .quote .quote__text {
        font-size: 23px;
        line-height: 30px; } }
  .quote .quote__text::before {
    content: "»"; }
  .quote .quote__text::after {
    content: "«"; }
  .quote .quote__cite {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    display: block;
    font-style: normal;
    color: currentColor;
    margin-top: calc(var(--spacing-unit) * 4); }

.slider,
.swiper-wrapper {
  overflow: visible;
  position: relative; }

.slider__slide {
  width: auto;
  height: 60vh; }

.slider[data-centered="true"] .slider__slide {
  height: auto; }

.slider__slide-image {
  background-color: var(--gray-200);
  overflow: hidden;
  width: auto;
  height: calc(100% - var(--spacing-unit) * 2 - 1.35em); }

.slider__slide img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  mix-blend-mode: multiply;
  display: block; }

.slider--centered .slider__slide {
  filter: grayscale(1);
  opacity: 0.25;
  transition: all 0.3s; }

.slider--centered .slider__slide--active {
  filter: grayscale(0);
  opacity: 1; }

.slider__button--disabled {
  opacity: 0; }

.slider__button-prev,
.slider__button-next {
  color: var(--primary-color); }

.slider__slide-caption {
  margin-top: calc(var(--spacing-unit) * 2);
  max-width: calc(var(--spacing-unit) * 55); }

.slide__slide-caption-dash {
  color: var(--primary-color); }

.text {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  max-width: 22em; }
  @media (max-width: 599px) {
    .text {
      font-size: 23px;
      line-height: 30px; } }
  .text a {
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
    text-decoration-skip-ink: auto;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px; }
  @media (max-width: 599px) {
    .text {
      font-size: 36px;
      line-height: 45px;
      letter-spacing: -0.004em;
      font-weight: 400;
      line-height: 45.5px; } }
  @media (max-width: 599px) and (max-width: 599px) {
    .text {
      font-size: 23px;
      line-height: 30px; } }

.video {
  padding: 0;
  margin: 0; }
  .video .video__frame {
    margin: 0;
    height: 0;
    padding-top: calc(9 / 16 * 100%);
    position: relative; }
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    width: 100%;
    border: none; }
  .video .video__caption {
    margin-top: calc(var(--spacing-unit) * 2);
    max-width: calc(var(--spacing-unit) * 55);
    counter-increment: figures;
    hyphens: manual;
    display: grid;
    grid-template-columns: auto auto 1fr;
    column-gap: calc(var(--spacing-unit) * 1); }
    .video .video__caption::before {
      content: counter(figures);
      color: var(--primary-color); }
    .video .video__caption .video__dash {
      color: var(--gray-400); }

.archive-project-list {
  position: relative;
  margin-top: calc(var(--spacing-unit) * -3); }
  .archive-project-list::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background-color: var(--white); }

.archive-project-list__project {
  padding: calc(var(--spacing-unit) * 3) 0;
  list-style: none;
  border-top: 1px solid var(--gray-300); }

.archive-project-list__project--filtered {
  display: none; }

.archive-project-list__project-link {
  text-decoration: none;
  display: grid;
  grid-template-columns: calc(var(--spacing-unit) * 10) minmax(0, auto) 1fr;
  column-gap: calc(var(--spacing-unit) * 3); }

.archive-project-list__project-year {
  color: var(--gray-500); }

.archive-project-list__project-title {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 600;
  color: var(--primary-color); }

.archive-project-list__project-read-more {
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-skip-ink: auto;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  transition: all 0.3s; }

.archive-project-list__project-link:hover .archive-project-list__project-read-more {
  text-decoration-color: var(--primary-color); }

.archive-project-list__project-read-more .arrow-right {
  transform: translateX(0);
  transition: all 0.3s;
  margin-left: calc(var(--spacing-unit) * 0.66);
  color: var(--gray-500); }

.archive-project-list__project-link:hover .archive-project-list__project-read-more .arrow-right {
  transform: translateX(calc(var(--spacing-unit) * 1)); }

.archive-project-list__project-subtitle {
  display: none; }

.archive-project-list__project-tags {
  margin-left: calc(var(--spacing-unit) * 10); }

.archive-project-list__project-tags .tags {
  justify-content: flex-end; }

@media (max-width: 1200px) {
  .archive-project-list__project-link {
    grid-template-columns: calc(var(--spacing-unit) * 8) 1fr 0; } }

.call-to-action-banner {
  position: relative;
  width: 100%;
  background-color: var(--primary-color-light);
  padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 5);
  border-radius: calc(var(--spacing-unit) * 4);
  margin: calc(var(--spacing-unit) * 20) 0;
  border: 1px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300; }
  @media (max-width: 599px) {
    .call-to-action-banner {
      font-size: 23px;
      line-height: 30px; } }
  .call-to-action-banner:last-child {
    margin-bottom: 0; }

.call-to-action-banner__intro {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0em;
  font-weight: 600;
  line-height: 45.5px;
  display: block;
  color: var(--primary-color-dark);
  text-decoration: none; }
  @media (max-width: 599px) {
    .call-to-action-banner__intro {
      font-size: 23px;
      line-height: 30px; } }

.call-to-action-banner__links {
  margin-top: calc(var(--spacing-unit) * 2);
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  column-gap: calc(var(--spacing-unit) * 3);
  opacity: 0.7; }
  @media (max-width: 599px) {
    .call-to-action-banner__links {
      font-size: 21px;
      line-height: 29px; } }

.call-to-action-banner__link {
  display: grid;
  grid-auto-flow: column;
  column-gap: calc(var(--spacing-unit) * 1);
  justify-content: start; }
  .call-to-action-banner__link .arrow-right {
    opacity: 0.3;
    transform: translateY(0.5em); }
  .call-to-action-banner__link a {
    text-decoration-color: rgba(0, 0, 0, 0.15); }

.call-to-action-banner[data-theme="dark"] {
  color: var(--white);
  background-color: var(--deepdive-blue); }
  .call-to-action-banner[data-theme="dark"] .call-to-action-banner__intro {
    color: var(--primary-color); }
  .call-to-action-banner[data-theme="dark"] .call-to-action-banner__links a:not(:hover) {
    text-decoration-color: rgba(255, 255, 255, 0.15); }

.call-to-action-banner--small {
  width: auto;
  max-width: max-content;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 4);
  border-radius: calc(var(--spacing-unit) * 3); }
  @media (max-width: 599px) {
    .call-to-action-banner--small {
      font-size: 21px;
      line-height: 29px; } }
  .call-to-action-banner--small .call-to-action-banner__intro {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 600; }
    @media (max-width: 599px) {
      .call-to-action-banner--small .call-to-action-banner__intro {
        font-size: 21px;
        line-height: 29px; } }
  .call-to-action-banner--small .call-to-action-banner__links {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; }

@media (max-width: 900px) {
  .call-to-action-banner {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 400; } }
  @media (max-width: 900px) and (max-width: 599px) {
    .call-to-action-banner {
      font-size: 21px;
      line-height: 29px; } }

@media (max-width: 900px) {
    .call-to-action-banner .call-to-action-banner__intro {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0em;
      font-weight: 600; } }
    @media (max-width: 900px) and (max-width: 599px) {
      .call-to-action-banner .call-to-action-banner__intro {
        font-size: 21px;
        line-height: 29px; } }

@media (max-width: 900px) {
  .call-to-action-banner__links {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; } }

@media (max-width: 599px) {
  .call-to-action-banner {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 3);
    border-radius: calc(var(--spacing-unit) * 2);
    margin: calc(var(--spacing-unit) * 10) 0; }
    .call-to-action-banner .call-to-action-banner__intro {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.01em;
      font-weight: 600; }
    .call-to-action-banner .call-to-action-banner__links {
      grid-auto-flow: row;
      row-gap: calc(var(--spacing-unit) * 1.5); } }

.deepdive-teaser-section {
  grid-column: 1 / -1;
  padding-top: calc(var(--spacing-unit) * 15);
  padding-bottom: calc(var(--spacing-unit) * 43);
  background-color: var(--deepdive-blue-dark);
  overflow: hidden;
  margin-bottom: calc(var(--spacing-unit) * -51); }

.deepdive-teaser-section__intro {
  grid-column: 3 / -2;
  color: var(--white); }

.deepdive-teaser-section__kicker {
  color: var(--primary-color);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em; }

.deepdive-teaser-section__text {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  margin-top: calc(var(--spacing-unit) * 3);
  color: var(--white);
  max-width: 22em;
  opacity: 0.7; }
  @media (max-width: 599px) {
    .deepdive-teaser-section__text {
      font-size: 23px;
      line-height: 30px; } }

.deepdive-teaser-section__teasers {
  grid-column: 2 / -2;
  margin-top: calc(var(--spacing-unit) * 10);
  overflow: visible;
  position: relative; }
  .deepdive-teaser-section__teasers .swiper-container {
    overflow: visible; }
  .deepdive-teaser-section__teasers .swiper-slide {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
  .deepdive-teaser-section__teasers .swiper-button-prev {
    left: 0; }
  .deepdive-teaser-section__teasers .swiper-button-next {
    right: 0; }

.deepdive-teaser-section__teasers-button {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  position: absolute;
  color: var(--primary-color);
  top: 0;
  transform: translateY(calc(-100% - var(--spacing-unit) * 8));
  cursor: pointer;
  padding: calc(var(--spacing-unit) * 2); }
  .deepdive-teaser-section__teasers-button svg {
    height: calc(var(--spacing-unit) * 4);
    width: calc(var(--spacing-unit) * 4); }

.deepdive-teaser-section__teasers-button-prev {
  left: 0;
  margin-left: calc(var(--spacing-unit) * -2); }
  .deepdive-teaser-section__teasers-button-prev svg {
    transform: rotate(180deg); }

.deepdive-teaser-section__teasers-button-next {
  right: 0;
  margin-right: calc(var(--spacing-unit) * -2); }

.deepdive-teaser-section__teasers-button--disabled {
  opacity: 0.5; }

@media (max-width: 900px) {
  .deepdive-teaser-section {
    padding-top: calc(var(--spacing-unit) * 12);
    padding-bottom: calc(var(--spacing-unit) * 12);
    margin-bottom: calc(var(--spacing-unit) * -15); }
  .deepdive-teaser-section__text {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 400; } }
  @media (max-width: 900px) and (max-width: 599px) {
    .deepdive-teaser-section__text {
      font-size: 21px;
      line-height: 29px; } }

@media (max-width: 599px) {
  .deepdive-teaser-section {
    padding-top: calc(var(--spacing-unit) * 8);
    padding-bottom: calc(var(--spacing-unit) * 18); }
  .deepdive-teaser-section__intro {
    grid-column: 2 / -2; }
  .deepdive-teaser-section__text {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    margin-top: calc(var(--spacing-unit) * 2); }
  .deepdive-teaser-section__teasers-button {
    transform: translateY(calc(100% + var(--spacing-unit) * 3));
    bottom: 0;
    top: unset; }
  .deepdive-teaser-section__teasers {
    margin-top: calc(var(--spacing-unit) * 6); } }

.deepdive-teaser {
  height: calc(var(--spacing-unit) * 65);
  overflow: hidden;
  display: block;
  position: relative;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.7);
  background-color: var(--deepdive-blue-light);
  border-radius: calc(var(--spacing-unit) * 2);
  text-decoration: none;
  transition: all 0.3s; }
  .deepdive-teaser:hover {
    transform: scale(1.05) translateY(-4%); }

.deepdive-teaser--large {
  height: auto; }

.deepdive-teaser__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  filter: grayscale(1) brightness(0.7);
  mix-blend-mode: multiply;
  background-color: var(--background-color);
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: calc(var(--spacing-unit) * 2); }

.deepdive-teaser__cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: all 0.3s;
  transform: scale(1.08); }

.deepdive-teaser--large .deepdive-teaser__cover {
  filter: blur(10px); }

.deepdive-teaser:hover .deepdive-teaser__cover {
  transition: transform 5s, filter 1s;
  transform: scale(1.3);
  filter: blur(0px); }

.deepdive-teaser--large.deepdive-teaser:hover .deepdive-teaser__cover {
  transition: transform 5s, filter 3s;
  transform: scale(1.3);
  filter: blur(0px); }

.deepdive-teaser__info {
  z-index: 2;
  height: 100%;
  position: relative;
  color: var(--white);
  padding: calc(var(--spacing-unit) * 3.66) calc(var(--spacing-unit) * 3);
  padding-right: calc(var(--spacing-unit) * 6);
  display: grid;
  grid-template-rows: auto;
  align-items: end;
  row-gap: calc(var(--spacing-unit) * 5); }

.deepdive-teaser__text {
  display: none; }

.deepdive-teaser__kicker {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: calc(var(--spacing-unit) * 1); }

.deepdive-teaser__title {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 400;
  line-height: 45.5px;
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-decoration-skip-ink: auto;
  text-underline-offset: 4px;
  text-decoration-thickness: 3px;
  max-width: 13em; }
  @media (max-width: 599px) {
    .deepdive-teaser__title {
      font-size: 23px;
      line-height: 30px; } }

.deepdive-teaser--large {
  height: unset; }
  .deepdive-teaser--large:hover {
    transform: scale(1.02) translateY(-2%); }
  .deepdive-teaser--large .deepdive-teaser__info {
    padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 5);
    row-gap: calc(var(--spacing-unit) * 15); }
  .deepdive-teaser--large .deepdive-teaser__info {
    grid-template-rows: 1fr max-content; }
  .deepdive-teaser--large .deepdive-teaser__text {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 400;
    max-width: 21em;
    opacity: 0.8;
    display: block;
    align-self: start; }
    @media (max-width: 599px) {
      .deepdive-teaser--large .deepdive-teaser__text {
        font-size: 21px;
        line-height: 29px; } }
  .deepdive-teaser--large .deepdive-teaser__kicker {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 600; }
    @media (max-width: 599px) {
      .deepdive-teaser--large .deepdive-teaser__kicker {
        font-size: 21px;
        line-height: 29px; } }
  .deepdive-teaser--large .deepdive-teaser__title {
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.031em;
    font-weight: 300;
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
    text-decoration-skip-ink: auto;
    text-underline-offset: 8px;
    text-decoration-thickness: 4px; }
    @media (max-width: 900px) {
      .deepdive-teaser--large .deepdive-teaser__title {
        font-size: 60px;
        line-height: 67px; } }
    @media (max-width: 599px) {
      .deepdive-teaser--large .deepdive-teaser__title {
        font-size: 42px;
        line-height: 54px; } }

@media (max-width: 900px) {
  .deepdive-teaser:hover {
    transform: none; } }

@media (max-width: 599px) {
  .deepdive-teaser {
    height: calc(var(--spacing-unit) * 45); }
  .deepdive-teaser__title {
    text-underline-offset: 3px;
    text-decoration-thickness: 2px; }
  .deepdive-teaser--large {
    height: unset; }
    .deepdive-teaser--large:hover {
      transform: none; }
    .deepdive-teaser--large .deepdive-teaser__info {
      row-gap: calc(var(--spacing-unit) * 12); }
    .deepdive-teaser--large .deepdive-teaser__text {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.007em;
      font-weight: 400; }
    .deepdive-teaser--large .deepdive-teaser__title {
      text-underline-offset: 5px;
      text-decoration-thickness: 3px; } }

.footer {
  margin-top: calc(var(--spacing-unit) * 30);
  overflow: hidden; }

.footer__top {
  grid-template-rows: auto calc(var(--spacing-unit) * 26); }

.footer__top-title {
  margin-bottom: calc(var(--spacing-unit) * 3); }
  .footer__top-title strong {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    font-weight: 600;
    color: var(--primary-color-dark); }

.footer__top-link {
  text-decoration: none;
  margin-left: calc(var(--spacing-unit) * 2); }

.footer__top-link:hover {
  text-decoration: inherit; }

.footer__top-link .arrow-right {
  transform: translateX(0);
  transition: all 0.3s;
  opacity: 0.5; }

.footer__top-link:hover .arrow-right {
  transform: translateX(calc(var(--spacing-unit) * 1)); }

.footer__featured-project {
  grid-column: 2 / 6;
  grid-row: 1 / 3;
  padding-right: calc(var(--spacing-unit) * 3); }
  .footer__featured-project .project-teaser {
    height: calc(var(--spacing-unit) * 40);
    box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.2); }

.footer__instagram {
  grid-column: 2 / -2;
  grid-row: 1 / 3; }

.footer--show-project .footer__instagram {
  grid-column: 6 / -2; }

.footer--show-project .footer__instagram-title,
.footer--show-project .footer__instagram-title strong {
  color: var(--gray-600); }

.footer__instagram-feed {
  overflow: visible;
  display: grid;
  grid-auto-flow: column;
  column-gap: calc(var(--spacing-unit) * 3);
  grid-auto-columns: calc(var(--spacing-unit) * 40);
  grid-auto-rows: calc(var(--spacing-unit) * 40); }

.footer__top-background {
  grid-column: 1 / -1;
  background-color: var(--gray-200);
  grid-row: 2; }

.footer__instagram-feed-loading {
  display: none; }

.footer__instagram-feed-loading:only-child {
  display: block;
  grid-column: span 12;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  color: var(--gray-500); }
  @media (max-width: 599px) {
    .footer__instagram-feed-loading:only-child {
      font-size: 23px;
      line-height: 30px; } }

.footer__instagram-image {
  grid-column: span 1;
  display: block;
  background-color: var(--gray-200);
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.2); }

.footer__instagram-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
  mix-blend-mode: multiply; }

.footer__instagram-image-link {
  display: none; }

.footer__bottom {
  background-color: var(--gray-200);
  padding-top: calc(var(--spacing-unit) * 10);
  padding-bottom: calc(var(--spacing-unit) * 7);
  row-gap: calc(var(--spacing-unit) * 10); }

.footer__bio {
  grid-column: 2 / 7;
  grid-row: 1;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.007em;
  font-weight: 400;
  color: var(--gray-800); }
  .footer__bio p {
    max-width: 22em; }
  .footer__bio p:first-child {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 400;
    max-width: 28em;
    color: var(--primary-color-dark); }
    @media (max-width: 599px) {
      .footer__bio p:first-child {
        font-size: 21px;
        line-height: 29px; } }
  .footer__bio a {
    text-decoration-color: var(--gray-400);
    text-underline-offset: 2px;
    text-decoration-thickness: 2px; }

.footer__links {
  grid-column: 7 / -2;
  grid-row: 1;
  justify-self: end;
  align-self: end;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: calc(var(--spacing-unit) * 14); }

.footer__link-list-title {
  margin-bottom: calc(var(--spacing-unit) * 1);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: var(--primary-color); }

.footer__link-list li {
  list-style: none;
  margin-bottom: calc(var(--spacing-unit) * 0.66); }

.footer__link-list a {
  text-decoration: none; }

.footer__link-list a:hover {
  opacity: 0.7; }

.footer__link-list a.active {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px; }

.footer__link-list-vertical .footer__link-list:not(:last-child) {
  margin-bottom: calc(var(--spacing-unit) * 5); }

.footer[data-theme="dark"]:not(.footer--top-link-light) .footer__top-title strong,
.footer[data-theme="very-dark"]:not(.footer--top-link-light) .footer__top-title strong {
  color: var(--deepdive-blue); }

.footer[data-theme="dark"] .footer__top-background,
.footer[data-theme="very-dark"] .footer__top-background {
  background-color: var(--deepdive-blue); }

.footer[data-theme="dark"] .footer__bottom,
.footer[data-theme="very-dark"] .footer__bottom {
  background-color: var(--deepdive-blue);
  color: var(--white); }

.footer[data-theme="dark"] .footer__bio,
.footer[data-theme="very-dark"] .footer__bio {
  color: rgba(255, 255, 255, 0.6); }
  .footer[data-theme="dark"] .footer__bio a:not(:hover),
  .footer[data-theme="very-dark"] .footer__bio a:not(:hover) {
    text-decoration-color: rgba(255, 255, 255, 0.3); }

.footer--top-link-light .footer__top-link {
  color: var(--white); }

.footer[data-theme="very-dark"] .footer__top-background {
  background-color: var(--deepdive-blue-dark); }

.footer[data-theme="very-dark"] .footer__bottom {
  background-color: var(--deepdive-blue-dark); }

@media (max-width: 1200px) {
  .footer--show-project .footer__featured-project {
    display: none; }
  .footer--show-project .footer__instagram {
    grid-column: 2 / -2; }
  .footer__bio {
    grid-column: 2 / -2;
    grid-row: 1; }
  .footer__links {
    grid-column: 2 / -2;
    grid-row: 2;
    justify-self: start; } }

@media (max-width: 900px) {
  .footer {
    margin-top: calc(var(--spacing-unit) * 15); }
  .footer__top {
    display: none; }
  .footer__bottom {
    padding-top: calc(var(--spacing-unit) * 8); }
  .footer__links {
    justify-items: space-between; } }

@media (max-width: 680px) {
  .footer__links {
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    row-gap: calc(var(--spacing-unit) * 5); } }

@media (max-width: 599px) {
  .footer {
    margin-top: calc(var(--spacing-unit) * 15); }
  .footer__bottom {
    padding-top: calc(var(--spacing-unit) * 6); }
  .footer__bio p:first-child {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; }
  .footer__link-list li {
    margin-bottom: 0; }
  .footer__link-list a {
    display: block;
    padding: 3px 0; } }

.header__languages-wrapper {
  padding-top: calc(var(--spacing-unit) * 3);
  height: calc(var(--spacing-unit) * 12);
  margin-bottom: calc(var(--spacing-unit) * -12);
  align-items: center;
  z-index: 7;
  position: relative;
  pointer-events: none; }

.header__languages {
  grid-column: 2 / -2;
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
  padding-right: calc(var(--spacing-unit) * 40); }

.header__language {
  text-decoration: none;
  opacity: 0.6;
  pointer-events: all; }
  .header__language:hover {
    opacity: 0.8; }
  .header__language--active {
    opacity: 1; }

.header__language-separator {
  opacity: 0.3; }

.header__content {
  width: 100%;
  padding-top: calc(var(--spacing-unit) * 3);
  height: calc(var(--spacing-unit) * 12);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 6;
  pointer-events: none; }

.header__logo {
  grid-row: 1;
  grid-column: 11 / -2;
  justify-self: end;
  align-self: center;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 600; }
  @media (max-width: 599px) {
    .header__logo {
      font-size: 21px;
      line-height: 29px; } }
  .header__logo a {
    text-decoration: none;
    text-transform: uppercase;
    color: var(--primary-color);
    letter-spacing: 0.04em;
    pointer-events: all;
    padding: calc(var(--spacing-unit) * 2);
    padding-right: 0;
    margin-left: calc(var(--spacing-unit) * -2); }

.header__nav {
  grid-row: 1;
  grid-column: 2 / 11;
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  column-gap: calc(var(--spacing-unit) * 4); }

.header .nav {
  list-style: none;
  margin: 0;
  padding: 0;
  transition: color 0.3s;
  pointer-events: all; }

.header .nav__item {
  display: inline-block; }

.header .nav__item + .nav__item {
  margin-left: calc(var(--spacing-unit) * 4); }

.header .nav-link {
  display: inline-block;
  text-decoration: none;
  transition: none;
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;
  transition: all 0.3s;
  display: inline-block; }

.header .nav-link--active {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-decoration-thickness: 2px; }

.header .nav-link:hover {
  opacity: 0.7; }

.header .nav__sub-page-separator {
  color: rgba(0, 0, 0, 0.15); }

.header .nav__sub-page {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  max-width: calc(var(--spacing-unit) * 43);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom; }

.header-mobile {
  display: none;
  width: 100%;
  padding-top: calc(var(--spacing-unit) * 1);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 6;
  pointer-events: none; }

.header-mobile .header__logo {
  grid-column: 2 / 9;
  justify-self: start; }

.header-mobile__menu {
  grid-row: 1;
  grid-column: 9 / -2;
  text-align: right; }

.header-mobile__menu-open-button {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  pointer-events: all;
  padding: calc(var(--spacing-unit) * 2);
  margin-right: calc(var(--spacing-unit) * -2); }
  .header-mobile__menu-open-button:active {
    opacity: 0.7; }

.header-mobile__menu-close {
  grid-row: 1;
  grid-column: 9 / -2;
  text-align: right;
  display: none; }

.header-mobile__menu-close-button {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  pointer-events: all;
  padding: calc(var(--spacing-unit) * 2);
  margin-right: calc(var(--spacing-unit) * -2);
  opacity: 0.6; }

.header[data-theme="light"] .header__languages {
  color: rgba(255, 255, 255, 0.6); }

.header[data-theme="light"] .nav {
  color: var(--white); }

.header[data-theme="light"] .nav__sub-page-separator {
  color: rgba(255, 255, 255, 0.15); }

.header[data-theme="light"] .nav__sub-page {
  color: rgba(255, 255, 255, 0.6); }

.header[data-theme="light"] .header-mobile__menu-open-button {
  color: var(--white); }

@media (max-width: 1400px) {
  .header__content .header__logo {
    grid-column: 10 / -2; }
  .header__nav {
    grid-column: 2 / 10; }
  .nav__sub-page-separator {
    display: none; }
  .header .nav__sub-page {
    display: none; } }

@media (max-width: 1160px) {
  .header__languages-wrapper {
    display: none; }
  .header__content {
    background-color: var(--deepdive-blue);
    color: var(--white);
    height: auto;
    padding-bottom: calc(var(--spacing-unit) * 5);
    --shadow-color: rgba(0, 0, 0, 0);
    box-shadow: 0 10px 40px -10px var(--shadow-color);
    transform: translateY(-100%);
    transition: all 0.5s ease-in;
    z-index: 7; }
  .header--mobile-visible .header__content {
    transform: translateY(0);
    --shadow-color: rgba(0, 0, 0, 0.2);
    transition: all 0.6s ease-out; }
  .header__content .header__logo {
    grid-row: 1;
    grid-column: 2 / -2;
    justify-self: center;
    align-self: center; }
  .header__nav {
    grid-row: 2;
    grid-column: 2 / -2; }
  .header .nav {
    margin-top: calc(var(--spacing-unit) * 3);
    margin-left: calc(var(--spacing-unit) * -1);
    padding-left: calc(var(--spacing-unit) * 3);
    padding-right: calc(var(--spacing-unit) * 3);
    text-align: center; }
  .header .nav__item {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.004em;
    font-weight: 300;
    padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 2);
    margin-left: calc(var(--spacing-unit) * 1);
    opacity: 0;
    transition: opacity 0.6s 0s; } }
  @media (max-width: 1160px) and (max-width: 599px) {
    .header .nav__item {
      font-size: 23px;
      line-height: 30px; } }

@media (max-width: 1160px) {
  .header .nav__item + .nav__item {
    margin-left: calc(var(--spacing-unit) * 1); }
  .header .nav__item:nth-child(1) {
    transition-delay: calc(0.1s * 1); }
  .header .nav__item:nth-child(2) {
    transition-delay: calc(0.1s * 2); }
  .header .nav__item:nth-child(3) {
    transition-delay: calc(0.1s * 3); }
  .header .nav__item:nth-child(4) {
    transition-delay: calc(0.1s * 4); }
  .header .nav__item:nth-child(5) {
    transition-delay: calc(0.1s * 5); }
  .header .nav__item:nth-child(6) {
    transition-delay: calc(0.1s * 6); }
  .header .nav__item:nth-child(7) {
    transition-delay: calc(0.1s * 7); }
  .header .nav__item:nth-child(8) {
    transition-delay: calc(0.1s * 8); }
  .header .nav__item:nth-child(9) {
    transition-delay: calc(0.1s * 9); }
  .header .nav__item:nth-child(10) {
    transition-delay: calc(0.1s * 10); }
  .header .nav-link {
    text-underline-offset: 4px; }
  .header--mobile-visible .nav .nav__item {
    opacity: 1; }
  .header-mobile {
    display: grid; }
  .header-mobile__menu-close {
    display: block; } }

@media (max-width: 599px) {
  .header__content {
    padding-top: calc(var(--spacing-unit) * 1); }
  .header__content .header__logo {
    grid-column: 2 / 9;
    justify-self: start;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    font-weight: 600; }
  .header .nav {
    margin-top: calc(var(--spacing-unit) * 2); }
  .header .nav__item {
    display: block; }
  .header-mobile .header__logo {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    font-weight: 600; } }

.key-visual {
  height: 650px;
  position: relative;
  overflow: hidden; }
  .key-visual .key-visual__image {
    display: block;
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    overflow: visible; }
  .key-visual .key-visual__image img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .key-visual .key-visual__image--top img {
    object-position: top; }

@media (max-width: 1000px) {
  .key-visual {
    height: 500px; } }

.next-projects {
  margin-top: calc(var(--spacing-unit) * 20);
  grid-column: 2 / -2; }

.next-projects__title {
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.031em;
  font-weight: 300; }
  @media (max-width: 900px) {
    .next-projects__title {
      font-size: 60px;
      line-height: 67px; } }
  @media (max-width: 599px) {
    .next-projects__title {
      font-size: 42px;
      line-height: 54px; } }

.next-projects__grid {
  margin-top: calc(var(--spacing-unit) * 6);
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: calc(var(--spacing-unit) * 4);
  row-gap: calc(var(--spacing-unit) * 4); }

@media (max-width: 900px) {
  .next-projects__grid {
    grid-auto-flow: row; } }

@media (max-width: 599px) {
  .next-projects {
    margin-top: calc(var(--spacing-unit) * 10); }
  .next-projects__title {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -0.015em;
    font-weight: 300; } }
  @media (max-width: 599px) and (max-width: 599px) {
    .next-projects__title {
      font-size: 36px;
      line-height: 43px; } }

.project-teaser {
  height: calc(var(--spacing-unit) * 70);
  overflow: hidden;
  display: block;
  position: relative;
  background-color: var(--background-color);
  cursor: pointer; }

.project-teaser__cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: all 0.5s; }

.project-teaser__info {
  position: absolute;
  bottom: calc(var(--spacing-unit) * 2.33);
  left: calc(var(--spacing-unit) * 3);
  right: calc(var(--spacing-unit) * 3);
  z-index: 2;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: calc(var(--spacing-unit) * 4);
  align-items: center; }

.project-teaser__title {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 600;
  color: var(--primary-color); }

.project-teaser__read-more {
  color: var(--secondary-color);
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-skip-ink: auto;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  transition: all 0.3s; }

.project-teaser:hover .project-teaser__read-more {
  text-decoration-color: var(--primary-color); }

.project-teaser__read-more .arrow-right {
  transform: translateX(0);
  transition: all 0.3s;
  margin-left: calc(var(--spacing-unit) * 0.66);
  opacity: 0.5; }

.project-teaser:hover .project-teaser__read-more .arrow-right {
  transform: translateX(calc(var(--spacing-unit) * 1)); }

.project-teaser__tags {
  justify-self: end;
  width: 100%; }

.project-teaser__tags .tags {
  justify-content: flex-end; }

.project-teaser__tags .tag {
  opacity: 0;
  transform: translateY(50%);
  transition: all 0.3s; }
  .project-teaser__tags .tag:nth-child(1) {
    transition-delay: 0s; }
  .project-teaser__tags .tag:nth-child(2) {
    transition-delay: 0s; }
  .project-teaser__tags .tag:nth-child(3) {
    transition-delay: 0s; }
  .project-teaser__tags .tag:nth-child(4) {
    transition-delay: 0s; }

.project-teaser:hover .project-teaser__tags .tag {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.5s; }
  .project-teaser:hover .project-teaser__tags .tag:nth-child(1) {
    transition-delay: 0s; }
  .project-teaser:hover .project-teaser__tags .tag:nth-child(2) {
    transition-delay: 0.1s; }
  .project-teaser:hover .project-teaser__tags .tag:nth-child(3) {
    transition-delay: 0.2s; }
  .project-teaser:hover .project-teaser__tags .tag:nth-child(4) {
    transition-delay: 0.3s; }

.project-teaser--overlay::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  height: calc(var(--spacing-unit) * 30);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)); }

@media (max-width: 599px) {
  .project-teaser {
    height: calc(var(--spacing-unit) * 50); } }

.tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--spacing-unit) * -1);
  margin-bottom: calc(var(--spacing-unit) * -1.33); }

.tag {
  display: block;
  margin-right: var(--spacing-unit);
  margin-bottom: calc(var(--spacing-unit) * 1.33);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  white-space: nowrap;
  color: var(--primary-color);
  background-color: var(--background-color);
  display: inline-block;
  border-radius: 1.7em;
  line-height: 1.7em;
  padding: 0.1em 0.7em 0; }

.tag--active {
  background-color: var(--active-color);
  color: var(--white); }

.tag__count {
  opacity: 0.5;
  display: inline-block;
  padding-left: 0.2em; }

.travel-journal-entry-meta {
  max-width: max-content;
  display: flex;
  flex-wrap: wrap;
  column-gap: calc(var(--spacing-unit) * 1.5);
  row-gap: calc(var(--spacing-unit) * 0.75);
  background-color: var(--entry-color-medium);
  padding: 3px;
  border-radius: calc(var(--spacing-unit) * 3);
  z-index: 2;
  position: relative; }

.travel-journal-entry-meta__datum {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: calc(var(--spacing-unit) * 1.2);
  align-items: center;
  text-decoration: none;
  padding: calc(var(--spacing-unit) * 0.7) calc(var(--spacing-unit) * 1.75) calc(var(--spacing-unit) * 0.75) calc(var(--spacing-unit) * 0.7);
  border-radius: calc(var(--spacing-unit) * 3 - 3px);
  height: 38px; }
  .travel-journal-entry-meta__datum .icon {
    transform: scale(0.93); }
  .travel-journal-entry-meta__datum .icon__light {
    opacity: 0.2; }
  .travel-journal-entry-meta__datum .icon__dark {
    opacity: 0.4; }
  .travel-journal-entry-meta__datum:first-child {
    padding-left: calc(var(--spacing-unit) * 1); }
  .travel-journal-entry-meta__datum:last-child {
    padding-right: calc(var(--spacing-unit) * 2.25); }

@media (hover: hover) {
  a.travel-journal-entry-meta__datum:hover {
    background-color: rgba(255, 255, 255, 0.8); } }

.travel-journal-entry-meta__datum-content {
  font-size: 14.5px;
  line-height: 1.1em;
  opacity: 0.95; }
  .travel-journal-entry-meta__datum-content p {
    margin: 0; }
  .travel-journal-entry-meta__datum-content p:not(:first-child) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    font-weight: 400;
    line-height: 1em;
    margin-top: 0.18em;
    opacity: 0.6; }

.travel-journal-entry-teaser {
  color: var(--entry-color-dark);
  display: block;
  text-decoration: none;
  display: grid;
  grid-template-columns: calc(var(--spacing-unit) * 2) 1fr calc(var(--spacing-unit) * 2);
  align-self: start;
  transition: all 0.3s 0.1s; }

@media (hover: hover) {
  .travel-journal-entry-teaser:hover {
    transform: translateY(calc(var(--spacing-unit) * -2)); } }

.travel-journal-entry-teaser__background {
  background-color: var(--entry-color-light);
  border-radius: calc(var(--spacing-unit) * 2);
  grid-column: 2 / -2;
  grid-row: 1/ 4; }

.travel-journal-entry-teaser__image {
  grid-column: 1 /-1;
  grid-row: 1;
  transition: all 0.3s; }

@media (hover: hover) {
  .travel-journal-entry-teaser:hover .travel-journal-entry-teaser__image {
    transform: translateY(calc(var(--spacing-unit) * -1)); } }

.travel-journal-entry-teaser__title {
  grid-column: 2;
  grid-row: 2;
  padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 1);
  overflow: hidden; }
  .travel-journal-entry-teaser__title h2 {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 600;
    line-height: 1.1em; }
    @media (max-width: 599px) {
      .travel-journal-entry-teaser__title h2 {
        font-size: 21px;
        line-height: 29px; } }

.travel-journal-entry-teaser__location-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.travel-journal-entry-teaser__date {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.007em;
  font-weight: 400;
  opacity: 0.7;
  margin-top: 0em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.travel-journal-entry-teaser__link {
  grid-column: 2;
  grid-row: 3;
  padding-left: calc(var(--spacing-unit) * 3);
  padding-right: calc(var(--spacing-unit) * 3);
  padding-bottom: calc(var(--spacing-unit) * 3);
  opacity: 0.5;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.007em;
  font-weight: 400;
  font-size: 16px;
  line-height: 1em;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-decoration-color: transparent;
  transition: all 0.6s; }
  .travel-journal-entry-teaser__link .arrow-right {
    transform: translateX(0);
    transition: all 0.3s;
    margin-left: calc(var(--spacing-unit) * 0.66);
    opacity: 0.5; }

@media (hover: hover) {
  .travel-journal-entry-teaser:hover .travel-journal-entry-teaser__link {
    text-decoration-color: currentColor;
    opacity: 1; }
    .travel-journal-entry-teaser:hover .travel-journal-entry-teaser__link .arrow-right {
      transform: translateX(calc(var(--spacing-unit) * 1)); } }

.travel-journal-entry-teaser__image img,
.travel-journal-entry-teaser__image video {
  border-radius: calc(var(--spacing-unit) * 1.5);
  aspect-ratio: 1;
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; }

.travel-journal-entry-teaser--minimal .travel-journal-entry-teaser__image {
  grid-row: 1; }

.travel-journal-entry-teaser--minimal .travel-journal-entry-teaser__title {
  grid-row: 2;
  opacity: 0.5;
  grid-column: 1/-1;
  padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 2.5);
  color: var(--entry-color-bright); }

.travel-journal-entry-teaser--minimal .travel-journal-entry-teaser__background {
  display: none; }

.travel-journal-entry-teaser--minimal .travel-journal-entry-teaser__location-title {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 600;
  line-height: 1.1em; }

.travel-journal-entry-teaser--minimal .travel-journal-entry-teaser__link {
  display: none; }

.travel-journal-entry-card {
  grid-column: 2 / -2;
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
  color: var(--entry-color-dark);
  transition: transform 0.3s 0.1s;
  display: block;
  text-decoration: none; }

.travel-journal-entry-card__background {
  background-color: var(--entry-color-light);
  border-radius: calc(var(--spacing-unit) * 5); }

.travel-journal-entry-card__title {
  display: block;
  text-decoration: none;
  margin-top: calc(var(--spacing-unit) * 4);
  margin-left: calc(var(--spacing-unit) * 4);
  margin-right: calc(var(--spacing-unit) * 4);
  margin-bottom: calc(var(--spacing-unit) * 4);
  grid-area: title; }
  .travel-journal-entry-card__title h2 {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0em;
    font-weight: 600;
    line-height: 45.5px;
    line-height: 1.15em; }
    @media (max-width: 599px) {
      .travel-journal-entry-card__title h2 {
        font-size: 23px;
        line-height: 30px; } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card__title {
    margin-top: calc(var(--spacing-unit) * 3);
    margin-left: calc(var(--spacing-unit) * 3);
    margin-right: calc(var(--spacing-unit) * 3);
    margin-bottom: calc(var(--spacing-unit) * 3); } }

.travel-journal-entry-card__date {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  opacity: 0.7;
  margin-top: 0.25em; }
  @media (max-width: 599px) {
    .travel-journal-entry-card__date {
      font-size: 21px;
      line-height: 29px; } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card__date {
    margin-top: 0em; } }

.travel-journal-entry-card__location-title {
  white-space: nowrap;
  hyphens: manual; }

.travel-journal-entry-card__location-title-text {
  white-space: normal; }

.travel-journal-entry-card__tags {
  display: inline-block;
  background-color: var(--entry-color-medium);
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 1em;
  border-radius: calc(var(--spacing-unit) * 1);
  padding: 0.4em 0.5em;
  opacity: 1;
  vertical-align: 0.7em; }

.travel-journal-entry-card__images {
  grid-area: images;
  display: block; }

.travel-journal-entry-card__image {
  display: none;
  transition: all 0.3s; }
  .travel-journal-entry-card__image--0 {
    display: block; }
  .travel-journal-entry-card__image--1 {
    transition-delay: 0.1s; }
  .travel-journal-entry-card__image--2 {
    transition-delay: 0.2s; }
  .travel-journal-entry-card__image--3 {
    transition-delay: 0.3s; }

.travel-journal-entry-card__image img,
.travel-journal-entry-card__image video {
  border-radius: calc(var(--spacing-unit) * 2);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; }

@media (hover: hover) {
  .travel-journal-entry-card:hover .travel-journal-entry-card__image {
    transform: translateY(calc(var(--spacing-unit) * -2)); } }

.travel-journal-entry-card__content {
  display: block;
  text-decoration: none;
  grid-area: content;
  transition: all 0.3s;
  margin-left: calc(var(--spacing-unit) * 4);
  margin-right: calc(var(--spacing-unit) * 8); }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card__content {
    margin-left: calc(var(--spacing-unit) * 3);
    margin-right: calc(var(--spacing-unit) * 3);
    font-size: 16px;
    line-height: 22px; } }

.travel-journal-entry-card__text {
  opacity: 0.8;
  max-width: 28em; }

.travel-journal-entry-card__link {
  opacity: 0.5;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-decoration-color: transparent;
  transition: all 0.6s; }
  .travel-journal-entry-card__link .arrow-right {
    transform: translateX(0);
    transition: all 0.3s;
    margin-left: calc(var(--spacing-unit) * 0.66);
    opacity: 0.5; }

@media (hover: hover) {
  .travel-journal-entry-card:hover .travel-journal-entry-card__link {
    text-decoration-color: currentColor;
    opacity: 0.7; }
    .travel-journal-entry-card:hover .travel-journal-entry-card__link .arrow-right {
      transform: translateX(calc(var(--spacing-unit) * 1)); } }

.travel-journal-entry-card__meta {
  margin-top: calc(var(--spacing-unit) * 7);
  margin-right: calc(var(--spacing-unit) * 3);
  margin-left: calc(var(--spacing-unit) * 3);
  margin-bottom: calc(var(--spacing-unit) * 3);
  grid-area: meta;
  align-self: end; }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card__meta {
    margin-top: calc(var(--spacing-unit) * 5);
    margin-right: calc(var(--spacing-unit) * 2);
    margin-left: calc(var(--spacing-unit) * 2);
    margin-bottom: calc(var(--spacing-unit) * 2); }
    .travel-journal-entry-card__meta .travel-journal-entry-meta__country {
      display: none; } }

.travel-journal-entry-card--layout-single-wide {
  display: grid;
  grid-template-areas: "images images images images" "blank title content blank2" "blank meta content blank2";
  grid-template-columns: calc(var(--spacing-unit) * 6) auto 1fr calc(var(--spacing-unit) * 6);
  grid-template-rows: auto auto auto; }
  .travel-journal-entry-card--layout-single-wide .travel-journal-entry-card__background {
    grid-column: 2 / -2;
    grid-row: 1 / -1; }
  .travel-journal-entry-card--layout-single-wide .travel-journal-entry-card__image {
    height: auto;
    aspect-ratio: 2.3; }
  .travel-journal-entry-card--layout-single-wide .travel-journal-entry-card__content {
    margin-top: calc(var(--spacing-unit) * 4);
    margin-left: calc(var(--spacing-unit) * 10);
    margin-bottom: calc(var(--spacing-unit) * 6); }

@container card-container (max-width: 1200px) {
  .travel-journal-entry-card--layout-single-wide {
    grid-template-areas: "images images images images" "blank title title blank2" "blank content content blank2" "blank meta meta blank2";
    grid-template-rows: auto auto auto auto; }
    .travel-journal-entry-card--layout-single-wide .travel-journal-entry-card__content {
      margin-top: calc(var(--spacing-unit) * 0);
      margin-left: calc(var(--spacing-unit) * 4);
      margin-bottom: calc(var(--spacing-unit) * 4); }
    .travel-journal-entry-card--layout-single-wide .travel-journal-entry-card__meta {
      margin-top: calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-single-wide .travel-journal-entry-card__image {
      aspect-ratio: 1.85; } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card--layout-single-wide {
    grid-template-columns: calc(var(--spacing-unit) * 3) auto 1fr calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-single-wide .travel-journal-entry-card__content {
      margin-left: calc(var(--spacing-unit) * 3);
      margin-bottom: calc(var(--spacing-unit) * 2); }
    .travel-journal-entry-card--layout-single-wide .travel-journal-entry-card__image {
      aspect-ratio: 1.7; } }

.travel-journal-entry-card--layout-double-2-1-top {
  display: grid;
  grid-template-areas: "images images images images" "blank title content blank2" "blank meta content blank2";
  grid-template-columns: calc(var(--spacing-unit) * 6) auto 1fr calc(var(--spacing-unit) * 6);
  grid-template-rows: auto auto auto;
  transition-delay: 0.2s; }
  .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__background {
    grid-column: 2 / -2;
    grid-row: 1 / -1;
    margin-top: calc(var(--spacing-unit) * 20); }
  .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__images {
    display: grid;
    grid-template-columns: 1.5fr calc(var(--spacing-unit) * 8) 1fr;
    grid-template-rows: calc(var(--spacing-unit) * 10) auto calc(var(--spacing-unit) * 10); }
  .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__image--0 {
    grid-column: 1 / 3;
    grid-row: 2 / -1;
    z-index: 1;
    aspect-ratio: 1.5;
    width: auto;
    height: 100%;
    max-width: 100%; }
  .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__image--1 {
    grid-column: 2 / -1;
    grid-row: 1 / -2;
    display: block;
    aspect-ratio: 1;
    width: auto;
    height: auto;
    max-width: 100%; }
  .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__content {
    margin-top: calc(var(--spacing-unit) * 4);
    margin-left: calc(var(--spacing-unit) * 15);
    margin-bottom: calc(var(--spacing-unit) * 6); }

@container card-container (max-width: 1200px) {
  .travel-journal-entry-card--layout-double-2-1-top {
    grid-template-areas: "images images images" "blank title blank2" "blank content blank2" "blank meta blank2";
    grid-template-columns: calc(var(--spacing-unit) * 3) 1fr calc(var(--spacing-unit) * 3);
    grid-template-rows: auto auto auto auto; }
    .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__meta {
      margin-top: calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__content {
      margin-top: calc(var(--spacing-unit) * 0);
      margin-bottom: calc(var(--spacing-unit) * 4);
      margin-right: calc(var(--spacing-unit) * 4);
      margin-left: calc(var(--spacing-unit) * 4); }
    .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__images {
      grid-template-rows: calc(var(--spacing-unit) * 6) auto calc(var(--spacing-unit) * 8); } }

@container card-container (max-width: 800px) {
  .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__images {
    display: grid;
    grid-template-columns: 3fr calc(var(--spacing-unit) * 10) 1.2fr;
    grid-template-rows: calc(var(--spacing-unit) * 3) auto calc(var(--spacing-unit) * 6); } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__images {
    grid-template-columns: 3fr calc(var(--spacing-unit) * 15) 1fr; }
  .travel-journal-entry-card--layout-double-2-1-top .travel-journal-entry-card__content {
    margin-left: calc(var(--spacing-unit) * 3);
    margin-bottom: calc(var(--spacing-unit) * 2); } }

.travel-journal-entry-card--layout-double-1-2-top {
  display: grid;
  grid-template-areas: "images images images images" "blank title content blank2" "blank meta content blank2";
  grid-template-columns: calc(var(--spacing-unit) * 6) auto 1fr calc(var(--spacing-unit) * 6);
  grid-template-rows: auto auto auto;
  transition-delay: 0.2s; }
  .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__background {
    grid-column: 2 / -2;
    grid-row: 1 / -1;
    margin-top: calc(var(--spacing-unit) * 20); }
  .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__images {
    display: grid;
    grid-template-columns: 1fr calc(var(--spacing-unit) * 10) 2fr;
    grid-template-rows: calc(var(--spacing-unit) * 10) auto calc(var(--spacing-unit) * 6); }
  .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__image--0 {
    grid-column: 2 / -1;
    grid-row: 1 / -2;
    aspect-ratio: 1.6;
    width: auto;
    height: auto;
    z-index: 1; }
  .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__image--1 {
    grid-column: 1 / 3;
    grid-row: 2 / -1;
    display: block;
    aspect-ratio: 0.9;
    width: auto;
    height: 100%;
    max-width: 100%; }
  .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__content {
    margin-top: calc(var(--spacing-unit) * 6);
    margin-left: calc(var(--spacing-unit) * 15);
    margin-bottom: calc(var(--spacing-unit) * 6); }

@container card-container (max-width: 1200px) {
  .travel-journal-entry-card--layout-double-1-2-top {
    grid-template-areas: "images images images" "blank title blank2" "blank content blank2" "blank meta blank2";
    grid-template-columns: calc(var(--spacing-unit) * 3) 1fr calc(var(--spacing-unit) * 3);
    grid-template-rows: auto auto auto auto; }
    .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__meta {
      margin-top: calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__content {
      margin-top: calc(var(--spacing-unit) * 0);
      margin-bottom: calc(var(--spacing-unit) * 4);
      margin-right: calc(var(--spacing-unit) * 4);
      margin-left: calc(var(--spacing-unit) * 4); } }

@container card-container (max-width: 800px) {
  .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__images {
    display: grid;
    grid-template-columns: 1fr calc(var(--spacing-unit) * 10) 3fr;
    grid-template-rows: calc(var(--spacing-unit) * 6) auto calc(var(--spacing-unit) * 2); } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__images {
    grid-template-columns: 1fr calc(var(--spacing-unit) * 10) 3fr; }
  .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__image--0 {
    aspect-ratio: 1.3; }
  .travel-journal-entry-card--layout-double-1-2-top .travel-journal-entry-card__content {
    margin-left: calc(var(--spacing-unit) * 3);
    margin-bottom: calc(var(--spacing-unit) * 2); } }

.travel-journal-entry-card--layout-single-square-right-bottom {
  display: grid;
  grid-template-areas: "title images images" "content images images" "meta images images" "blank images images";
  grid-template-columns: 1fr 1fr calc(var(--spacing-unit) * 10);
  grid-template-rows: auto 1fr auto calc(var(--spacing-unit) * 10); }
  .travel-journal-entry-card--layout-single-square-right-bottom .travel-journal-entry-card__background {
    grid-column: 1 / -2;
    grid-row: 1/ -2; }
  .travel-journal-entry-card--layout-single-square-right-bottom .travel-journal-entry-card__images {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    padding-top: calc(var(--spacing-unit) * 3); }
  .travel-journal-entry-card--layout-single-square-right-bottom .travel-journal-entry-card__image {
    height: 100%;
    width: 100%; }

@container card-container (max-width: 800px) {
  .travel-journal-entry-card--layout-single-square-right-bottom {
    grid-template-areas: "blank images images" "blank5 images images" "title title blank4" "content content blank3" "meta meta blank2";
    grid-template-rows: calc(var(--spacing-unit) * 10) auto auto 1fr auto;
    grid-template-columns: calc(var(--spacing-unit) * 3) 1fr calc(var(--spacing-unit) * 6); }
    .travel-journal-entry-card--layout-single-square-right-bottom .travel-journal-entry-card__background {
      grid-column: 1 / -2;
      grid-row: 2 / -1; }
    .travel-journal-entry-card--layout-single-square-right-bottom .travel-journal-entry-card__images {
      padding-top: 0; }
    .travel-journal-entry-card--layout-single-square-right-bottom .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 4); }
    .travel-journal-entry-card--layout-single-square-right-bottom .travel-journal-entry-card__meta {
      margin-top: calc(var(--spacing-unit) * 3); } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card--layout-single-square-right-bottom {
    grid-template-columns: calc(var(--spacing-unit) * 2) 1fr calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-single-square-right-bottom .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 2); } }

.travel-journal-entry-card--layout-single-square-right-top {
  display: grid;
  grid-template-areas: "blank images images" "title images images" "content images images" "meta images images";
  grid-template-columns: 1fr 1fr calc(var(--spacing-unit) * 10);
  grid-template-rows: calc(var(--spacing-unit) * 10) auto 1fr auto; }
  .travel-journal-entry-card--layout-single-square-right-top .travel-journal-entry-card__background {
    grid-column: 1 / -2;
    grid-row: 2 / -1; }
  .travel-journal-entry-card--layout-single-square-right-top .travel-journal-entry-card__images {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    padding-bottom: calc(var(--spacing-unit) * 3); }
  .travel-journal-entry-card--layout-single-square-right-top .travel-journal-entry-card__image {
    height: 100%;
    width: 100%; }

@container card-container (max-width: 800px) {
  .travel-journal-entry-card--layout-single-square-right-top {
    grid-template-areas: "blank images images" "blank5 images images" "title title blank4" "content content blank3" "meta meta blank2";
    grid-template-rows: calc(var(--spacing-unit) * 10) auto auto 1fr auto;
    grid-template-columns: calc(var(--spacing-unit) * 3) 1fr calc(var(--spacing-unit) * 6); }
    .travel-journal-entry-card--layout-single-square-right-top .travel-journal-entry-card__background {
      grid-column: 1 / -2;
      grid-row: 2 / -1; }
    .travel-journal-entry-card--layout-single-square-right-top .travel-journal-entry-card__images {
      padding-bottom: 0; }
    .travel-journal-entry-card--layout-single-square-right-top .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 4); }
    .travel-journal-entry-card--layout-single-square-right-top .travel-journal-entry-card__meta {
      margin-top: calc(var(--spacing-unit) * 3); } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card--layout-single-square-right-top {
    grid-template-columns: calc(var(--spacing-unit) * 2) 1fr calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-single-square-right-top .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 2); } }

.travel-journal-entry-card--layout-double-square-right-top {
  display: grid;
  grid-template-areas: "blank images images" "title images images" "content images images" "meta images images";
  grid-template-columns: 1fr 1fr calc(var(--spacing-unit) * 10);
  grid-template-rows: calc(var(--spacing-unit) * 10) auto 1fr auto;
  transition: transform 0.3s 0.2s; }
  .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__background {
    grid-column: 1 / -2;
    grid-row: 2 / -1; }
  .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__images {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    padding-bottom: calc(var(--spacing-unit) * 10);
    padding-right: calc(var(--spacing-unit) * 20);
    position: relative; }
  .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__image {
    height: 100%;
    width: 100%; }
  .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__image--0 {
    height: 100%;
    width: 100%;
    aspect-ratio: 1; }
  .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__image--1 {
    display: block;
    position: absolute;
    right: calc(var(--spacing-unit) * 0);
    bottom: calc(var(--spacing-unit) * -10);
    width: 50%;
    height: auto;
    aspect-ratio: 0.8; }

@container card-container (max-width: 800px) {
  .travel-journal-entry-card--layout-double-square-right-top {
    grid-template-areas: "blank images images" "blank5 images images" "title title blank4" "content content blank3" "meta meta blank2";
    grid-template-rows: calc(var(--spacing-unit) * 10) auto auto 1fr auto;
    grid-template-columns: calc(var(--spacing-unit) * 3) 1fr calc(var(--spacing-unit) * 6); }
    .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__background {
      grid-column: 1 / -2;
      grid-row: 2 / -1; }
    .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__images {
      padding-bottom: calc(var(--spacing-unit) * 7);
      padding-right: calc(var(--spacing-unit) * 15); }
    .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__image--1 {
      bottom: calc(var(--spacing-unit) * 0);
      width: 40%; }
    .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 4); }
    .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__meta {
      margin-top: calc(var(--spacing-unit) * 3); } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card--layout-double-square-right-top {
    grid-template-columns: calc(var(--spacing-unit) * 2) 1fr calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__images {
      padding-bottom: calc(var(--spacing-unit) * 5);
      padding-right: calc(var(--spacing-unit) * 10); }
    .travel-journal-entry-card--layout-double-square-right-top .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 2); } }

.travel-journal-entry-card--layout-single-square-left-top {
  display: grid;
  grid-template-areas: "images images blank" "images images title" "images images content" "blank2 meta meta";
  grid-template-columns: calc(var(--spacing-unit) * 10) 1fr 1fr;
  grid-template-rows: calc(var(--spacing-unit) * 10) auto 1fr auto; }
  .travel-journal-entry-card--layout-single-square-left-top .travel-journal-entry-card__background {
    grid-column: 2 / -1;
    grid-row: 2 / -1; }
  .travel-journal-entry-card--layout-single-square-left-top .travel-journal-entry-card__images {
    width: 100%;
    height: 100%;
    aspect-ratio: 1; }
  .travel-journal-entry-card--layout-single-square-left-top .travel-journal-entry-card__image {
    height: 100%;
    width: 100%; }
  .travel-journal-entry-card--layout-single-square-left-top .travel-journal-entry-card__meta {
    margin-top: calc(var(--spacing-unit) * 3); }
  .travel-journal-entry-card--layout-single-square-left-top .travel-journal-entry-card__content {
    align-self: end; }

@container card-container (max-width: 800px) {
  .travel-journal-entry-card--layout-single-square-left-top {
    grid-template-areas: "images images blank" "images images blank5" "blank4 title title" "blank3 content content" "blank2 meta meta";
    grid-template-rows: calc(var(--spacing-unit) * 10) auto auto 1fr auto;
    grid-template-columns: calc(var(--spacing-unit) * 6) 1fr calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-single-square-left-top .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 4); } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card--layout-single-square-left-top {
    grid-template-columns: calc(var(--spacing-unit) * 3) 1fr calc(var(--spacing-unit) * 2); }
    .travel-journal-entry-card--layout-single-square-left-top .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 2); } }

.travel-journal-entry-card--layout-single-portrait-left {
  display: grid;
  grid-template-areas: "images blank" "images title" "images content" "images meta" "images blank2";
  grid-template-columns: 1fr auto;
  grid-template-rows: calc(var(--spacing-unit) * 6) auto 1fr auto calc(var(--spacing-unit) * 6); }
  .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__background {
    grid-column: 1 / -1;
    grid-row: 2 / -2; }
  .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__images {
    align-self: stretch;
    max-width: 400px; }
  .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__image {
    height: 100%;
    max-width: 100%;
    aspect-ratio: 0.65; }
  .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__content {
    margin-bottom: calc(var(--spacing-unit) * 10); }

@container card-container (max-width: 800px) {
  .travel-journal-entry-card--layout-single-portrait-left {
    grid-template-areas: "images images blank" "images images blank" "blank2 title title" "blank2 content content " "blank2 meta meta";
    grid-template-columns: calc(var(--spacing-unit) * 6) 1fr auto;
    grid-template-rows: calc(var(--spacing-unit) * 6) auto auto auto 1fr auto; }
    .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__background {
      grid-column: 2 / -1;
      grid-row: 2 / -2; }
    .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__images {
      max-width: 100%;
      margin-right: calc(var(--spacing-unit) * 6); }
    .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 4); }
    .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__meta {
      margin-top: calc(var(--spacing-unit) * 3); } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card--layout-single-portrait-left {
    grid-template-columns: calc(var(--spacing-unit) * 3) 1fr auto; }
    .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__images {
      margin-right: calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-single-portrait-left .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 2); } }

.travel-journal-entry-card--layout-single-portrait-right {
  display: grid;
  grid-template-areas: "blank images" "title images" "content images" "meta images" "blank2 images";
  grid-template-columns: 1fr auto;
  grid-template-rows: calc(var(--spacing-unit) * 6) auto 1fr auto calc(var(--spacing-unit) * 6); }
  .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__background {
    grid-column: 1 / -1;
    grid-row: 2 / -2; }
  .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__images {
    align-self: stretch;
    max-width: 400px; }
  .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__image {
    height: 100%;
    max-width: 100%;
    aspect-ratio: 0.8; }
  .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__content {
    margin-bottom: calc(var(--spacing-unit) * 10); }

@container card-container (max-width: 800px) {
  .travel-journal-entry-card--layout-single-portrait-right {
    grid-template-areas: "blank images images" "blank images images" "title title blank2" "content content blank2 " "meta meta blank2";
    grid-template-columns: auto 1fr calc(var(--spacing-unit) * 6);
    grid-template-rows: calc(var(--spacing-unit) * 6) auto auto auto 1fr auto; }
    .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__background {
      grid-column: 1 / -2;
      grid-row: 2 / -2; }
    .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__images {
      max-width: 100%;
      margin-left: calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 4); }
    .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__meta {
      margin-top: calc(var(--spacing-unit) * 3); } }

@container card-container (max-width: 599px) {
  .travel-journal-entry-card--layout-single-portrait-right {
    grid-template-columns: auto 1fr calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__images {
      margin-left: calc(var(--spacing-unit) * 3); }
    .travel-journal-entry-card--layout-single-portrait-right .travel-journal-entry-card__content {
      margin-bottom: calc(var(--spacing-unit) * 2); } }

.about__intro {
  margin-top: calc(var(--spacing-unit) * 20);
  margin-bottom: calc(var(--spacing-unit) * 0);
  grid-column: 2 / -2;
  position: relative; }

.about__title {
  font-size: 110px;
  line-height: 110px;
  letter-spacing: -0.03em;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(var(--spacing-unit) * 9); }
  @media (max-width: 900px) {
    .about__title {
      font-size: 86px;
      line-height: 90px; } }
  @media (max-width: 599px) {
    .about__title {
      font-size: 54px;
      line-height: 58px; } }

.about__visual {
  max-width: calc(var(--spacing-unit) * 140); }

.about__visual svg {
  width: 100%;
  height: auto; }

.about__portrait {
  grid-column: 2 / -2;
  display: none; }
  .about__portrait img {
    max-width: 100%; }

.about__content {
  grid-column: 5 / -2;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  margin-top: calc(var(--spacing-unit) * -9); }
  @media (max-width: 599px) {
    .about__content {
      font-size: 23px;
      line-height: 30px; } }
  .about__content p {
    max-width: 22em;
    margin-bottom: 0.8em;
    hyphens: manual; }
  .about__content p:first-child {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -0.015em;
    font-weight: 600;
    color: var(--primary-color);
    max-width: 18em;
    margin-bottom: calc(var(--spacing-unit) * 10); }
    @media (max-width: 599px) {
      .about__content p:first-child {
        font-size: 36px;
        line-height: 43px; } }

.about__work-headline {
  grid-column: 2 / 5;
  margin-top: calc(var(--spacing-unit) * 15); }

.about__work {
  grid-column: 5 / -2;
  margin-top: calc(var(--spacing-unit) * 15); }

.about__sectors {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  margin-bottom: calc(var(--spacing-unit) * 8);
  max-width: 24em; }
  @media (max-width: 599px) {
    .about__sectors {
      font-size: 23px;
      line-height: 30px; } }

.about__sector-button {
  transition: all 0.3s 0.1s;
  background-color: var(--secondary-color);
  border-radius: 0.5em;
  padding: 0em 0.3em;
  line-height: 1.45em;
  white-space: nowrap; }

.about__sector-button--fade-out {
  background-color: var(--gray-300); }

.about__clients {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  max-width: 30em;
  line-height: 1.6em; }
  @media (max-width: 599px) {
    .about__clients {
      font-size: 21px;
      line-height: 29px; } }

.about__client,
.about__clients-fillers {
  transition: all 0.3s 0.1s; }

.about__clients--filtering .about__clients-fillers,
.about__clients--filtering .about__client--fade-out {
  color: var(--gray-400);
  text-decoration-color: transparent; }

.about__clients--filtering .about__client:not(.about__client--fade-out) {
  color: var(--black);
  text-decoration-color: var(--secondary-color); }

.about__cta {
  grid-column: 5 / -2; }

.about__fun-facts {
  grid-column: 1 / -1;
  background-color: var(--primary-color-light);
  margin-top: calc(var(--spacing-unit) * 20); }

.about__fun-facts-box {
  grid-column: 5 / -2;
  max-width: calc(var(--spacing-unit) * 90);
  padding: calc(var(--spacing-unit) * 10) 0;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.007em;
  font-weight: 400;
  counter-reset: step; }
  .about__fun-facts-box ol {
    margin: 0;
    padding-left: 1.8em; }
  .about__fun-facts-box li {
    margin-bottom: calc(var(--spacing-unit) * 2);
    list-style: none; }
    .about__fun-facts-box li:last-child {
      margin-bottom: 0; }
  .about__fun-facts-box li::before {
    content: counter(step);
    counter-increment: step;
    color: var(--black);
    border: 2px solid var(--secondary-color);
    display: inline-block;
    margin-right: calc(var(--spacing-unit) * 1.33);
    font-variant-numeric: tabular-nums;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    font-weight: 700;
    line-height: 1.65em;
    vertical-align: 0.15em;
    padding-left: 0.05em;
    width: 1.8em;
    height: 1.8em;
    border-radius: 0.9em;
    margin-left: -2.85em;
    margin-right: 1.2em; }
  .about__fun-facts-box p {
    display: inline; }

.about__services-headline {
  grid-column: 2 / 5;
  margin-top: calc(var(--spacing-unit) * 20); }

.about__services {
  grid-column: 5 / -2;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  margin-top: calc(var(--spacing-unit) * 20); }
  @media (max-width: 599px) {
    .about__services {
      font-size: 21px;
      line-height: 29px; } }

.about__services-list ul {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  line-height: 1.9em;
  margin-bottom: calc(var(--spacing-unit) * 8); }
  @media (max-width: 599px) {
    .about__services-list ul {
      font-size: 23px;
      line-height: 30px; } }

.about__services-list li {
  list-style: none;
  display: inline-block;
  border: 2px solid var(--secondary-color);
  border-radius: 0.5em;
  padding: 0em 0.4em;
  line-height: 1.5em;
  margin-right: 0.2em; }

.about__services-list p {
  max-width: 30em; }

.about__contact-headline {
  grid-column: 2 / 5;
  margin-top: calc(var(--spacing-unit) * 20); }

.about__contact {
  grid-column: 5 / -2;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  margin-top: calc(var(--spacing-unit) * 20);
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 599px) {
    .about__contact {
      font-size: 23px;
      line-height: 30px; } }

.about__contact-item {
  margin-right: calc(var(--spacing-unit) * 10);
  margin-bottom: calc(var(--spacing-unit) * 5); }

.about__contact-link-title {
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 600;
  color: var(--gray-600);
  margin-bottom: calc(var(--spacing-unit) * 0.5); }

.about__subheadline {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0em;
  font-weight: 600;
  line-height: 45.5px;
  color: var(--primary-color);
  margin-bottom: calc(var(--spacing-unit) * 5); }
  @media (max-width: 599px) {
    .about__subheadline {
      font-size: 23px;
      line-height: 30px; } }
  .about__subheadline--small {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: calc(var(--spacing-unit) * 4); }
    @media (max-width: 599px) {
      .about__subheadline--small {
        font-size: 21px;
        line-height: 29px; } }

@media (max-width: 1200px) {
  .about__content {
    margin-top: calc(var(--spacing-unit) * 3);
    grid-column: 4 / -2; }
  .about__work-headline {
    grid-column: 4 / -2; }
  .about__work {
    grid-column: 4 / -2;
    margin-top: calc(var(--spacing-unit) * 3); }
  .about__cta {
    grid-column: 4 / -2; }
  .about__fun-facts-box {
    grid-column: 4 / -2; }
  .about__services-headline {
    grid-column: 4 / -2;
    margin-top: calc(var(--spacing-unit) * 20); }
  .about__services {
    grid-column: 4 / -2;
    margin-top: 0; }
  .about__contact-headline {
    grid-column: 4 / -2;
    margin-top: calc(var(--spacing-unit) * 20); }
  .about__contact {
    grid-column: 4 / -2;
    margin-top: 0; } }

@media (max-width: 900px) {
  .about__content {
    grid-column: 2/ -2; }
  .about__fun-facts-box {
    grid-column: 2 / -2; }
  .about__work-headline {
    grid-column: 2 / -2; }
  .about__work {
    grid-column: 2 / -2;
    margin-top: calc(var(--spacing-unit) * 3); }
  .about__cta {
    grid-column: 2 / -2; }
  .about__services-headline {
    grid-column: 2 / -2;
    margin-top: calc(var(--spacing-unit) * 15); }
  .about__services {
    grid-column: 2 / -2;
    margin-top: calc(var(--spacing-unit) * 3); }
  .about__services-list ul {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 400;
    margin-bottom: calc(var(--spacing-unit) * 6); } }
  @media (max-width: 900px) and (max-width: 599px) {
    .about__services-list ul {
      font-size: 21px;
      line-height: 29px; } }

@media (max-width: 900px) {
  .about__services-list li {
    list-style: disc;
    display: list-item;
    border: none;
    padding: 0;
    line-height: 1.5em;
    margin-left: calc(var(--spacing-unit) * 4); }
    .about__services-list li::marker {
      color: var(--secondary-color); }
  .about__contact-headline {
    grid-column: 2 / -2;
    margin-top: calc(var(--spacing-unit) * 15); }
  .about__contact {
    grid-column: 2 / -2;
    margin-top: calc(var(--spacing-unit) * 3); }
  .about__subheadline {
    margin-bottom: calc(var(--spacing-unit) * 3); } }

@media (max-width: 599px) {
  .about__intro {
    margin-top: calc(var(--spacing-unit) * 17); }
  .about__title {
    display: none; }
  .about__visual {
    display: none; }
  .about__portrait {
    display: block; }
  .about__content {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; }
    .about__content p:first-child {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0em;
      font-weight: 600;
      margin-bottom: calc(var(--spacing-unit) * 3); } }
    @media (max-width: 599px) and (max-width: 599px) {
      .about__content p:first-child {
        font-size: 21px;
        line-height: 29px; } }

@media (max-width: 599px) {
  .about__work-headline {
    margin-top: calc(var(--spacing-unit) * 10); }
  .about__work {
    margin-top: 0; }
  .about__sectors {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    margin-bottom: calc(var(--spacing-unit) * 4);
    margin-top: 0; }
  .about__clients {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    line-height: 1.6em; }
  .about__services-headline {
    margin-top: calc(var(--spacing-unit) * 12); }
  .about__services {
    margin-top: 0; }
  .about__services-list {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; }
    .about__services-list ul {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.007em;
      font-weight: 400;
      margin-bottom: calc(var(--spacing-unit) * 4); }
  .about__contact-headline {
    margin-top: calc(var(--spacing-unit) * 12); }
  .about__contact {
    margin-top: 0;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; }
  .about__contact-link-title {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    font-weight: 700;
    margin-bottom: calc(var(--spacing-unit) * 0.5);
    text-transform: uppercase;
    letter-spacing: 0.08em; }
  .about__subheadline {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0em;
    font-weight: 600;
    line-height: 45.5px;
    margin-bottom: calc(var(--spacing-unit) * 3); } }
  @media (max-width: 599px) and (max-width: 599px) {
    .about__subheadline {
      font-size: 23px;
      line-height: 30px; } }

.archive {
  padding-top: calc(var(--spacing-unit) * 25); }

.archive__content {
  padding-bottom: calc(var(--spacing-unit) * 25); }

.archive__title {
  grid-column: 2 / -2;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.031em;
  font-weight: 300;
  margin-bottom: calc(var(--spacing-unit) * 4);
  display: none; }
  @media (max-width: 900px) {
    .archive__title {
      font-size: 60px;
      line-height: 67px; } }
  @media (max-width: 599px) {
    .archive__title {
      font-size: 42px;
      line-height: 54px; } }

.archive__left {
  grid-column: 10 / -2;
  grid-row: 1; }

.archive__left-content {
  position: sticky;
  top: calc(var(--spacing-unit) * 25); }

.archive__tags {
  display: grid;
  grid-auto-flow: row;
  row-gap: calc(var(--spacing-unit) * 5);
  padding-left: calc(var(--spacing-unit) * 8); }
  .archive__tags .tag {
    cursor: pointer;
    user-select: none; }

.archive__tags-kicker {
  margin-bottom: calc(var(--spacing-unit) * 1.33);
  margin-left: calc(var(--spacing-unit) * 0.66);
  color: var(--primary-color);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em; }

.archive__list {
  grid-column: 2 / 10;
  grid-row: 1;
  position: relative; }

@media (max-width: 1200px) {
  .archive__left {
    grid-column: 2 / -2;
    grid-row: 1; }
  .archive__tags {
    padding-left: 0; }
  .archive__list {
    grid-column: 2 / -2;
    grid-row: 2;
    margin-top: calc(var(--spacing-unit) * 10); } }

@media (max-width: 599px) {
  .archive {
    padding-top: calc(var(--spacing-unit) * 17); }
  .archive__title {
    display: block; }
  .archive__left {
    display: none; }
  .archive__list {
    margin-top: 0; } }

.contact__content {
  margin-top: calc(var(--spacing-unit) * 25);
  grid-column: 2 / -2; }

.contact__intro {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  color: var(--primary-color);
  margin-bottom: calc(var(--spacing-unit) * 6);
  max-width: 24em; }
  @media (max-width: 599px) {
    .contact__intro {
      font-size: 23px;
      line-height: 30px; } }

.contact__link {
  font-size: 110px;
  line-height: 110px;
  letter-spacing: -0.03em;
  font-weight: 300; }
  @media (max-width: 900px) {
    .contact__link {
      font-size: 86px;
      line-height: 90px; } }
  @media (max-width: 599px) {
    .contact__link {
      font-size: 54px;
      line-height: 58px; } }
  .contact__link a {
    text-decoration: underline;
    text-decoration-color: var(--secondary-color);
    text-decoration-skip-ink: auto;
    text-underline-offset: 20px;
    text-decoration-thickness: 4px; }

.contact__outro {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.007em;
  font-weight: 400;
  margin-top: calc(var(--spacing-unit) * 20);
  max-width: 28em;
  color: var(--gray-700); }

@media (max-width: 1200px) {
  .contact__link {
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.031em;
    font-weight: 300; } }
  @media (max-width: 1200px) and (max-width: 900px) {
    .contact__link {
      font-size: 60px;
      line-height: 67px; } }
  @media (max-width: 1200px) and (max-width: 599px) {
    .contact__link {
      font-size: 42px;
      line-height: 54px; } }

@media (max-width: 599px) {
  .contact__content {
    margin-top: calc(var(--spacing-unit) * 17); }
  .contact__intro {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    margin-bottom: calc(var(--spacing-unit) * 3); }
  .contact__link {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -0.015em;
    font-weight: 300; } }
  @media (max-width: 599px) and (max-width: 599px) {
    .contact__link {
      font-size: 36px;
      line-height: 43px; } }

@media (max-width: 599px) {
  .contact__link a {
    text-underline-offset: 8px;
    text-decoration-thickness: 2px; }
  .contact__outro {
    margin-top: calc(var(--spacing-unit) * 10); } }

.deepdive__header {
  padding-top: calc(var(--spacing-unit) * 32);
  padding-bottom: calc(var(--spacing-unit) * 12);
  background-color: var(--deepdive-blue-light);
  position: relative; }

.deepdive__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  filter: grayscale(1) brightness(0.5);
  mix-blend-mode: multiply;
  background-color: var(--background-color);
  width: 100%;
  height: 100%; }

.deepdive__cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: all 0.3s; }

.deepdive__info {
  grid-column: 2 / -2;
  color: var(--white);
  z-index: 2; }

.deepdive__kicker {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: calc(var(--spacing-unit) * 2); }
  @media (max-width: 599px) {
    .deepdive__kicker {
      font-size: 21px;
      line-height: 29px; } }

.deepdive__title {
  font-size: 110px;
  line-height: 110px;
  letter-spacing: -0.03em;
  font-weight: 300;
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-decoration-skip-ink: auto;
  text-underline-offset: 12px;
  text-decoration-thickness: 6px; }
  @media (max-width: 900px) {
    .deepdive__title {
      font-size: 86px;
      line-height: 90px; } }
  @media (max-width: 599px) {
    .deepdive__title {
      font-size: 54px;
      line-height: 58px; } }

.deepdive__content {
  padding-top: calc(var(--spacing-unit) * 13); }

.deepdive__intro {
  grid-column: 5 / -2;
  grid-row: 1;
  padding-left: calc(var(--spacing-unit) * 10); }
  .deepdive__intro p {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 400;
    color: var(--gray-800);
    max-width: 32em; }
    @media (max-width: 599px) {
      .deepdive__intro p {
        font-size: 21px;
        line-height: 29px; } }
    .deepdive__intro p strong {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0em;
      font-weight: 600; }
      @media (max-width: 599px) {
        .deepdive__intro p strong {
          font-size: 21px;
          line-height: 29px; } }
  .deepdive__intro p:first-child {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.004em;
    font-weight: 400;
    line-height: 45.5px;
    max-width: 24em;
    margin-bottom: calc(var(--spacing-unit) * 6);
    color: var(--black); }
    @media (max-width: 599px) {
      .deepdive__intro p:first-child {
        font-size: 23px;
        line-height: 30px; } }
    .deepdive__intro p:first-child strong {
      font-size: 36px;
      line-height: 45px;
      letter-spacing: 0em;
      font-weight: 600;
      line-height: 45.5px; }
      @media (max-width: 599px) {
        .deepdive__intro p:first-child strong {
          font-size: 23px;
          line-height: 30px; } }
  .deepdive__intro h2 {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
    text-decoration-skip-ink: auto;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
    margin-top: calc(var(--spacing-unit) * 5);
    margin-bottom: calc(var(--spacing-unit) * 2); }
    @media (max-width: 599px) {
      .deepdive__intro h2 {
        font-size: 21px;
        line-height: 29px; } }
  .deepdive__intro .call-to-action-banner {
    margin-top: calc(var(--spacing-unit) * 13); }

.deepdive__sidebar {
  grid-column: 2 / 5;
  grid-row: 1;
  margin-top: calc(var(--spacing-unit) * 1);
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 600;
  color: var(--secondary-color); }
  .deepdive__sidebar p {
    margin-bottom: 0.3em; }
  .deepdive__sidebar ul {
    margin-top: calc(var(--spacing-unit) * 0);
    margin-bottom: calc(var(--spacing-unit) * 6); }
  .deepdive__sidebar li {
    list-style: none;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
    text-decoration-skip-ink: auto;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
    position: relative;
    margin-left: 1em;
    margin-bottom: calc(var(--spacing-unit) * 1); }
    .deepdive__sidebar li::before {
      content: "… ";
      position: absolute;
      left: -1em; }

.deepdive__projects-title {
  grid-column: 2 / -2;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.031em;
  font-weight: 300;
  color: var(--secondary-color);
  margin-top: calc(var(--spacing-unit) * 28);
  margin-bottom: calc(var(--spacing-unit) * 15);
  max-width: 16.5em; }
  @media (max-width: 900px) {
    .deepdive__projects-title {
      font-size: 60px;
      line-height: 67px; } }
  @media (max-width: 599px) {
    .deepdive__projects-title {
      font-size: 42px;
      line-height: 54px; } }

.deepdive__projects {
  grid-column: 2 / -2;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: calc(var(--spacing-unit) * 4);
  row-gap: calc(var(--spacing-unit) * 20); }

.deepdive__project {
  display: block;
  grid-column: 1 / 7; }

.deepdive__project:nth-child(2),
.deepdive__project:nth-child(6) {
  grid-column: 5 / 11; }

.deepdive__project:nth-child(3),
.deepdive__project:nth-child(7) {
  grid-column: 2 / 8; }

.deepdive__project:nth-child(4),
.deepdive__project:nth-child(8) {
  grid-column: 6 / 12; }

.deepdive__project-caption {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  margin-bottom: calc(var(--spacing-unit) * 5);
  max-width: calc(var(--spacing-unit) * 100);
  hyphens: manual; }
  @media (max-width: 599px) {
    .deepdive__project-caption {
      font-size: 21px;
      line-height: 29px; } }

.deepdive__cta-banner {
  grid-column: 2 / -2; }

@media (max-width: 1200px) {
  .deepdive__intro {
    grid-column: 2 / -2;
    padding-left: 0; }
  .deepdive__sidebar {
    grid-column: 2 / -2;
    grid-row: 2;
    margin-top: calc(var(--spacing-unit) * 12); }
  .deepdive__projects-title {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -0.015em;
    font-weight: 300;
    margin-top: calc(var(--spacing-unit) * 15);
    margin-bottom: calc(var(--spacing-unit) * 10); } }
  @media (max-width: 1200px) and (max-width: 599px) {
    .deepdive__projects-title {
      font-size: 36px;
      line-height: 43px; } }

@media (max-width: 900px) {
  .deepdive__sidebar {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    font-weight: 600;
    margin-top: calc(var(--spacing-unit) * 8); }
    .deepdive__sidebar ul {
      margin-bottom: calc(var(--spacing-unit) * 5); }
    .deepdive__sidebar li {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.007em;
      font-weight: 400; }
  .deepdive__projects-title {
    margin-top: calc(var(--spacing-unit) * 10);
    margin-bottom: calc(var(--spacing-unit) * 8); }
  .deepdive__projects {
    row-gap: calc(var(--spacing-unit) * 10); }
  .deepdive__project {
    grid-column: 1/-1 !important; } }

@media (max-width: 599px) {
  .deepdive__header {
    padding-top: calc(var(--spacing-unit) * 25);
    padding-bottom: calc(var(--spacing-unit) * 10); }
  .deepdive__title {
    text-underline-offset: 8px;
    text-decoration-thickness: 4px; }
  .deepdive__content {
    padding-top: calc(var(--spacing-unit) * 6); }
  .deepdive__intro p {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; }
    .deepdive__intro p strong {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.01em;
      font-weight: 600; }
  .deepdive__intro p:first-child {
    margin-bottom: calc(var(--spacing-unit) * 5); }
  .deepdive__intro .call-to-action-banner {
    margin-top: calc(var(--spacing-unit) * 6); }
  .deepdive__projects-title {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.004em;
    font-weight: 400;
    line-height: 45.5px;
    margin-top: calc(var(--spacing-unit) * 14);
    margin-bottom: calc(var(--spacing-unit) * 6); } }
  @media (max-width: 599px) and (max-width: 599px) {
    .deepdive__projects-title {
      font-size: 23px;
      line-height: 30px; } }

@media (max-width: 599px) {
  .deepdive__project-caption {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    margin-bottom: calc(var(--spacing-unit) * 3); } }

.deepdives__content {
  margin-top: calc(var(--spacing-unit) * 25);
  grid-column: 2 / -2; }

.deepdives__title {
  font-size: 110px;
  line-height: 110px;
  letter-spacing: -0.03em;
  font-weight: 300;
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-decoration-skip-ink: auto;
  text-underline-offset: 12px;
  text-decoration-thickness: 6px;
  margin-bottom: calc(var(--spacing-unit) * 10); }
  @media (max-width: 900px) {
    .deepdives__title {
      font-size: 86px;
      line-height: 90px; } }
  @media (max-width: 599px) {
    .deepdives__title {
      font-size: 54px;
      line-height: 58px; } }

.deepdives__intro {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  max-width: 24em;
  margin-bottom: calc(var(--spacing-unit) * 20); }
  @media (max-width: 599px) {
    .deepdives__intro {
      font-size: 23px;
      line-height: 30px; } }

.deepdives__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: calc(var(--spacing-unit) * 4);
  row-gap: calc(var(--spacing-unit) * 4); }

@media (max-width: 1200px) {
  .deepdives__grid {
    grid-template-columns: 1fr 1fr 1fr; } }

@media (max-width: 900px) {
  .deepdives__grid {
    grid-template-columns: 1fr 1fr; }
  .deepdives__intro {
    margin-bottom: calc(var(--spacing-unit) * 15); } }

@media (max-width: 599px) {
  .deepdives__content {
    margin-top: calc(var(--spacing-unit) * 17); }
  .deepdives__title {
    font-size: 110px;
    line-height: 110px;
    letter-spacing: -0.03em;
    font-weight: 300;
    text-underline-offset: 8px;
    text-decoration-thickness: 4px;
    margin-bottom: calc(var(--spacing-unit) * 6); } }
  @media (max-width: 599px) and (max-width: 900px) {
    .deepdives__title {
      font-size: 86px;
      line-height: 90px; } }
  @media (max-width: 599px) and (max-width: 599px) {
    .deepdives__title {
      font-size: 54px;
      line-height: 58px; } }

@media (max-width: 599px) {
  .deepdives__intro {
    margin-bottom: calc(var(--spacing-unit) * 10); }
  .deepdives__grid {
    grid-template-columns: 1fr; } }

.default__content {
  margin-top: calc(var(--spacing-unit) * 25);
  grid-column: 2 / -2; }

.default__title {
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.031em;
  font-weight: 300;
  margin-bottom: calc(var(--spacing-unit) * 10); }
  @media (max-width: 900px) {
    .default__title {
      font-size: 60px;
      line-height: 67px; } }
  @media (max-width: 599px) {
    .default__title {
      font-size: 42px;
      line-height: 54px; } }

.default__content h2 {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 600;
  margin-top: calc(var(--spacing-unit) * 5);
  margin-bottom: calc(var(--spacing-unit) * 1);
  color: var(--primary-color); }

.home__intro {
  grid-column: 2 / 10;
  margin-top: calc(var(--spacing-unit) * 30);
  padding-bottom: calc(var(--spacing-unit) * 20); }

.home__intro-title {
  font-size: 38px;
  line-height: 47px;
  max-width: 21.5em;
  font-weight: 300;
  letter-spacing: -0.015em;
  hyphens: manual; }
  .home__intro-title strong {
    font-size: 52px;
    line-height: 56px;
    letter-spacing: -0.015em;
    font-weight: 600;
    color: var(--primary-color);
    display: inline-block;
    margin-bottom: calc(var(--spacing-unit) * 3); }

.home__intro-cta {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  max-width: 27em;
  color: var(--gray-600);
  margin-top: calc(var(--spacing-unit) * 6); }
  @media (max-width: 599px) {
    .home__intro-cta {
      font-size: 21px;
      line-height: 29px; } }
  .home__intro-cta a {
    text-decoration: underline;
    text-decoration-color: var(--gray-400);
    text-decoration-skip-ink: auto;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
    transition: all 0.3s; }
    .home__intro-cta a:hover {
      text-decoration-color: var(--primary-color);
      color: var(--black); }

.home__intro-visual {
  margin-top: calc(var(--spacing-unit) * 28);
  grid-column: 10 / -2;
  max-width: calc(var(--spacing-unit) * 140); }

.home__intro-visual svg {
  width: 100%;
  height: auto; }

.home__intro-arrow {
  display: inline-block;
  transform-origin: center center;
  transform: rotate(90deg) translateX(0.06em);
  display: none; }
  .home__intro-arrow svg {
    width: 0.7em;
    height: 0.7em; }

.home__key-visual {
  grid-column: 2 / -2; }

.home__selected-projects {
  grid-column: 2 / -2;
  padding-top: calc(var(--spacing-unit) * 18); }

.home__selected-projects-title {
  color: var(--primary-color);
  margin-bottom: calc(var(--spacing-unit) * 8); }

.home__selected-projects-tags {
  margin-bottom: calc(var(--spacing-unit) * 6);
  display: flex;
  justify-content: flex-start; }
  .home__selected-projects-tags > div {
    padding-right: calc(var(--spacing-unit) * 4); }
  .home__selected-projects-tags > div:first-child {
    padding-top: calc(var(--spacing-unit) * 4); }
  .home__selected-projects-tags > div:last-child {
    padding-right: 0; }
  .home__selected-projects-tags .tag {
    cursor: pointer;
    user-select: none; }

.home-selected-projects-tags-kicker {
  margin-bottom: calc(var(--spacing-unit) * 1);
  margin-left: calc(var(--spacing-unit) * 0.66);
  color: var(--gray-600);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em; }

.home__project-teasers {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: calc(var(--spacing-unit) * 4);
  row-gap: calc(var(--spacing-unit) * 4); }

.home__project-teaser {
  --teaser-columns: 4;
  grid-column: span var(--teaser-columns);
  position: relative; }

.home__archive-teaser {
  display: flex;
  align-items: end;
  background-color: #eef0f6;
  height: calc(var(--spacing-unit) * 70);
  overflow: hidden;
  padding: calc(var(--spacing-unit) * 3);
  border: 8px solid #e0e3eb;
  border-radius: 20px;
  position: relative;
  text-decoration: none; }
  @media (max-width: 599px) {
    .home__archive-teaser {
      height: calc(var(--spacing-unit) * 70); } }
  .home__archive-teaser .home__archive-teaser-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: calc(var(--spacing-unit) * 3);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.2) calc(var(--spacing-unit) * 30), black calc(var(--spacing-unit) * 40)); }
  .home__archive-teaser .archive-project-list {
    margin-top: calc(var(--spacing-unit) * -7);
    filter: blur(2px);
    transition: filter 1s ease-in-out;
    animation-name: scroll;
    animation-duration: 120s;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }
    .home__archive-teaser .archive-project-list::before {
      background-color: #eef0f6; }
    .home__archive-teaser .archive-project-list .archive-project-list__project {
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      padding: calc(var(--spacing-unit) * 2) 0;
      overflow: hidden; }
    .home__archive-teaser .archive-project-list .archive-project-list__project-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .home__archive-teaser .archive-project-list .archive-project-list__project-read-more {
      display: none; }
    .home__archive-teaser .archive-project-list .archive-project-list__project-subtitle {
      opacity: 0.2;
      display: inline; }

@keyframes scroll {
  0% {
    transform: translateY(0);
    opacity: 0; }
  2% {
    opacity: 1; }
  95% {
    opacity: 1; }
  100% {
    transform: translateY(calc(-100% + var(--spacing-unit) * 70));
    opacity: 0; } }
  .home__archive-teaser .home__archive-teaser-title {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.004em;
    font-weight: 400;
    line-height: 45.5px;
    line-height: 1.1em;
    hyphens: manual;
    padding-right: calc(var(--spacing-unit) * 3);
    color: var(--primary-color);
    align-self: send;
    z-index: 4; }
    @media (max-width: 599px) {
      .home__archive-teaser .home__archive-teaser-title {
        font-size: 23px;
        line-height: 30px; } }
  .home__archive-teaser .project-teaser__read-more {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    margin-top: calc(var(--spacing-unit) * 1); }
  .home__archive-teaser:hover .project-teaser__read-more {
    text-decoration-color: var(--primary-color); }
  .home__archive-teaser:hover .project-teaser__read-more .arrow-right {
    transform: translateX(calc(var(--spacing-unit) * 1)); }
  .home__archive-teaser:hover .archive-project-list {
    filter: blur(0); }

.home__travel-teaser {
  display: flex;
  align-items: end;
  background-color: #0c1627;
  height: calc(var(--spacing-unit) * 70);
  overflow: hidden;
  padding: calc(var(--spacing-unit) * 3);
  border: 8px solid #e0e4eb;
  border-radius: 20px;
  position: relative;
  text-decoration: none; }
  @media (max-width: 599px) {
    .home__travel-teaser {
      height: calc(var(--spacing-unit) * 70); } }
  .home__travel-teaser::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(var(--spacing-unit) * 20);
    background-color: #354664; }
  .home__travel-teaser .project-teaser__image {
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    display: block;
    mask-image: radial-gradient(ellipse 300% 70% at bottom left, rgba(0, 0, 0, 0.3) 20%, black 60%); }
    .home__travel-teaser .project-teaser__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .home__travel-teaser .home__travel-teaser-list {
    position: absolute;
    top: calc(var(--spacing-unit) * 0);
    left: calc(var(--spacing-unit) * -3);
    right: calc(var(--spacing-unit) * 0);
    bottom: 0;
    z-index: 2;
    mask-image: radial-gradient(ellipse 200% 100% at bottom left, rgba(0, 0, 0, 0.3) 0%, black 60%); }
    .home__travel-teaser .home__travel-teaser-list .home__travel-teaser-previews {
      display: grid;
      grid-auto-flow: column;
      margin-top: calc(var(--spacing-unit) * 3);
      grid-auto-columns: calc(var(--spacing-unit) * 50);
      grid-gap: calc(var(--spacing-unit) * 3);
      transform-origin: left top;
      width: max-content;
      transform: translateX(0) scale(0.75);
      animation-name: horizontalScroll;
      animation-duration: 250s;
      animation-timing-function: linear;
      animation-iteration-count: infinite; }

@keyframes horizontalScroll {
  0% {
    transform: translateX(0) scale(0.75);
    opacity: 0; }
  .3% {
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    transform: translateX(-60%) scale(0.75);
    opacity: 0; } }
  .home__travel-teaser .home__travel-teaser-title {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.004em;
    font-weight: 400;
    line-height: 45.5px;
    line-height: 1.1em;
    hyphens: manual;
    padding-right: calc(var(--spacing-unit) * 3);
    color: var(--white);
    align-self: send;
    z-index: 4;
    width: 100%; }
    @media (max-width: 599px) {
      .home__travel-teaser .home__travel-teaser-title {
        font-size: 23px;
        line-height: 30px; } }
  .home__travel-teaser .project-teaser__intro {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    color: var(--white);
    margin-top: calc(var(--spacing-unit) * 1.5);
    opacity: 0.7;
    max-width: 28em; }
  .home__travel-teaser .project-teaser__read-more {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    color: var(--white);
    margin-top: calc(var(--spacing-unit) * 0.5);
    opacity: 0.5; }
  .home__travel-teaser:hover .project-teaser__read-more {
    text-decoration-color: var(--primary-color); }
  .home__travel-teaser:hover .project-teaser__read-more .arrow-right {
    transform: translateX(calc(var(--spacing-unit) * 1)); }

.home__section-title {
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.031em;
  font-weight: 300; }
  @media (max-width: 900px) {
    .home__section-title {
      font-size: 60px;
      line-height: 67px; } }
  @media (max-width: 599px) {
    .home__section-title {
      font-size: 42px;
      line-height: 54px; } }

.home__cta-banner {
  grid-column: 2 / -2;
  margin-bottom: calc(var(--spacing-unit) * 20); }

@media (max-width: 1200px) {
  .home__intro {
    grid-column: 2 / -2;
    margin-top: calc(var(--spacing-unit) * 24);
    padding-bottom: calc(var(--spacing-unit) * 15); }
  .home__intro-visual {
    display: none; } }

@media (max-width: 900px) {
  .home__intro {
    padding-bottom: calc(var(--spacing-unit) * 10); }
  .home__intro-title {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.004em;
    font-weight: 300;
    hyphens: auto; } }
  @media (max-width: 900px) and (max-width: 599px) {
    .home__intro-title {
      font-size: 23px;
      line-height: 30px; } }

@media (max-width: 900px) {
    .home__intro-title strong {
      font-size: 36px;
      line-height: 45px;
      letter-spacing: 0em;
      font-weight: 600;
      line-height: 45.5px;
      display: inline; } }
    @media (max-width: 900px) and (max-width: 599px) {
      .home__intro-title strong {
        font-size: 23px;
        line-height: 30px; } }

@media (max-width: 900px) {
  .home__intro-cta {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; }
  .home__selected-projects {
    padding-top: calc(var(--spacing-unit) * 8); }
  .home__selected-projects-tags {
    display: none; }
  .home__project-teaser {
    grid-column: span 12; }
  .home__deepdive {
    padding-top: calc(var(--spacing-unit) * 12);
    padding-bottom: calc(var(--spacing-unit) * 9); }
  .home__archive {
    padding-top: calc(var(--spacing-unit) * 15); }
  .home__archive-left {
    grid-column: 2 / -2; }
  .home__archive-tags {
    display: none; }
  .home__archive-list {
    grid-column: 2 / -2;
    margin-top: calc(var(--spacing-unit) * 6); }
  .home__archive-project-link {
    grid-template-columns: calc(var(--spacing-unit) * 8) 1fr 0; } }

@media (max-width: 599px) {
  .home__intro {
    margin-top: calc(var(--spacing-unit) * 17);
    padding-bottom: calc(var(--spacing-unit) * 10); }
  .home__intro-title {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; }
    .home__intro-title strong {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.01em;
      font-weight: 600; }
  .home__intro-cta {
    margin-top: calc(var(--spacing-unit) * 2); }
  .home__cta-banner {
    margin-bottom: calc(var(--spacing-unit) * 10); }
  .home__deepdive {
    padding-top: calc(var(--spacing-unit) * 8);
    padding-bottom: calc(var(--spacing-unit) * 18); }
  .home__deepdive-intro {
    grid-column: 2 / -2; }
  .home__deepdive-teasers-button {
    transform: translateY(calc(100% + var(--spacing-unit) * 3));
    bottom: 0;
    top: unset; }
  .home__deepdive-teasers {
    margin-top: calc(var(--spacing-unit) * 4); }
  .home__archive {
    padding-top: calc(var(--spacing-unit) * 10); }
  .home__archive-list {
    margin-top: calc(var(--spacing-unit) * 4); } }

.illustration-prompt-generator__background {
  background-color: var(--secondary-color-light);
  grid-row: 1 / 3;
  grid-column: 1 / -1; }

.illustration-prompt-generator__kicker {
  grid-column: -2 / 2;
  grid-row: 1;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 600;
  color: var(--primary-color);
  margin-top: calc(var(--spacing-unit) * 24);
  margin-bottom: calc(var(--spacing-unit) * 3); }
  @media (max-width: 599px) {
    .illustration-prompt-generator__kicker {
      font-size: 21px;
      line-height: 29px; } }
  .illustration-prompt-generator__kicker a {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    font-weight: 600;
    color: var(--gray-500);
    text-decoration-color: transparent;
    vertical-align: 0.1em;
    margin-left: 0.5em; }

.illustration-prompt-generator__prompt {
  grid-row: 2;
  grid-column: -2 / 2;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.031em;
  font-weight: 300;
  color: var(--secondary-color);
  max-width: 17em;
  hyphens: manual;
  padding-bottom: calc(var(--spacing-unit) * 10); }
  @media (max-width: 900px) {
    .illustration-prompt-generator__prompt {
      font-size: 60px;
      line-height: 67px; } }
  @media (max-width: 599px) {
    .illustration-prompt-generator__prompt {
      font-size: 42px;
      line-height: 54px; } }

.illustration-prompt-generator__text {
  grid-column: -2 / 2;
  grid-row: 3;
  max-width: 32em;
  color: var(--gray-800);
  margin-top: calc(var(--spacing-unit) * 8); }
  .illustration-prompt-generator__text p:last-child {
    color: var(--primary-color); }

@media (max-width: 599px) {
  .illustration-prompt-generator__kicker {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    font-weight: 600;
    margin-top: calc(var(--spacing-unit) * 17);
    margin-bottom: calc(var(--spacing-unit) * 4); }
    .illustration-prompt-generator__kicker a {
      vertical-align: 0;
      display: block;
      margin-left: 0; }
  .illustration-prompt-generator__prompt {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -0.015em;
    font-weight: 300; } }
  @media (max-width: 599px) and (max-width: 599px) {
    .illustration-prompt-generator__prompt {
      font-size: 36px;
      line-height: 43px; } }

@media (max-width: 599px) {
  .illustration-prompt-generator__text {
    margin-top: calc(var(--spacing-unit) * 9); } }

.process__top-background {
  grid-column: 1 / -1;
  grid-row: 1;
  background-color: var(--primary-color-light); }

.process__top {
  margin-top: calc(var(--spacing-unit) * 25);
  grid-column: 2 / -2;
  grid-row: 1; }

.process__content {
  margin-top: calc(var(--spacing-unit) * 2);
  grid-column: 2 / -2;
  grid-row: 2; }

.process__intro {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  color: var(--primary-color-dark);
  margin-bottom: calc(var(--spacing-unit) * 14);
  max-width: 22em; }
  @media (max-width: 599px) {
    .process__intro {
      font-size: 23px;
      line-height: 30px; } }

.process__toc {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  counter-reset: step;
  margin-bottom: calc(var(--spacing-unit) * 10); }
  @media (max-width: 599px) {
    .process__toc {
      font-size: 21px;
      line-height: 29px; } }

.process__toc-step {
  display: inline-block;
  margin-bottom: calc(var(--spacing-unit) * 2); }

.process__toc-step-link {
  text-decoration: none;
  text-decoration-color: var(--primary-color);
  text-decoration-skip-ink: auto;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px; }
  .process__toc-step-link:hover {
    text-decoration: underline;
    text-decoration-color: var(--primary-color); }
    .process__toc-step-link:hover::before {
      color: var(--black);
      background-color: var(--secondary-color); }

.process__toc-step-link::before {
  content: counter(step);
  counter-increment: step;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 600;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  width: 1.5em;
  height: 1.5em;
  line-height: 1.3em;
  vertical-align: 0.05em;
  text-align: center;
  display: inline-block;
  border-radius: 0.75em;
  margin-right: calc(var(--spacing-unit) * 1.33);
  font-variant-numeric: tabular-nums; }

.process__toc-step-arrow {
  display: inline;
  margin: calc(var(--spacing-unit) * 1.33);
  margin-right: calc(var(--spacing-unit) * 2.33);
  color: var(--primary-color);
  color: var(--gray-500);
  vertical-align: -0.03em; }
  .process__toc-step-arrow svg {
    height: 0.6em;
    width: 0.6em; }

.process__toc-step:last-child .process__toc-step-arrow {
  display: none; }

.process__step {
  margin-top: calc(var(--spacing-unit) * 5);
  padding-top: calc(var(--spacing-unit) * 15); }

.process__step-kicker {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: calc(var(--spacing-unit) * 1); }

.process__step-headline {
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.031em;
  font-weight: 300;
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-decoration-skip-ink: auto;
  text-underline-offset: 10px;
  text-decoration-thickness: 3px;
  margin-bottom: calc(var(--spacing-unit) * 8); }
  @media (max-width: 900px) {
    .process__step-headline {
      font-size: 60px;
      line-height: 67px; } }
  @media (max-width: 599px) {
    .process__step-headline {
      font-size: 42px;
      line-height: 54px; } }

.process__step-text {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  margin-bottom: calc(var(--spacing-unit) * 6);
  max-width: 28em; }
  @media (max-width: 599px) {
    .process__step-text {
      font-size: 21px;
      line-height: 29px; } }

.process__step-deliverables-kicker {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-bottom: calc(var(--spacing-unit) * 1);
  color: var(--gray-600); }

.process__step-deliverables {
  color: var(--gray-800); }

@media (max-width: 599px) {
  .process__top {
    margin-top: calc(var(--spacing-unit) * 17); }
  .process__intro {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    margin-bottom: calc(var(--spacing-unit) * 5); }
  .process__toc {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    margin-bottom: calc(var(--spacing-unit) * 5);
    flex-direction: column; }
  .process__toc-step {
    margin-bottom: calc(var(--spacing-unit) * 1); }
  .process__toc-step-link::before {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    font-weight: 700;
    border-width: 1px;
    line-height: 1.49em;
    vertical-align: 0.14em;
    padding-left: 0.05em;
    width: 1.4em;
    height: 1.4em;
    border-radius: 0.75em; }
  .process__toc-step-arrow {
    display: none; }
  .process__step {
    margin-top: calc(var(--spacing-unit) * 0);
    padding-top: calc(var(--spacing-unit) * 13); }
  .process__step-kicker {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    font-weight: 700;
    margin-bottom: calc(var(--spacing-unit) * 1); }
  .process__step-headline {
    margin-bottom: calc(var(--spacing-unit) * 6); }
  .process__step-text {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    margin-bottom: calc(var(--spacing-unit) * 5); } }

.project__intro {
  margin-top: calc(var(--spacing-unit) * 28);
  grid-column: 2 / -2; }

.project__title {
  font-size: 110px;
  line-height: 110px;
  letter-spacing: -0.03em;
  font-weight: 300; }
  @media (max-width: 900px) {
    .project__title {
      font-size: 86px;
      line-height: 90px; } }
  @media (max-width: 599px) {
    .project__title {
      font-size: 54px;
      line-height: 58px; } }

.project__subtitle {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  color: var(--primary-color);
  margin-top: calc(var(--spacing-unit) * 5); }
  @media (max-width: 599px) {
    .project__subtitle {
      font-size: 23px;
      line-height: 30px; } }

.project__content-wrapper {
  grid-column: 1 / -1;
  overflow: hidden; }

.project__content {
  grid-column: 2 / -2;
  margin-top: calc(var(--spacing-unit) * 20);
  counter-reset: figures;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  --column-gap: calc(var(--spacing-unit) * 4);
  column-gap: var(--column-gap);
  row-gap: calc(var(--spacing-unit) * 18);
  overflow: visible; }

.content-block {
  grid-column: span var(--width);
  --columnWidth: calc((100% - (var(--width) - 1) * var(--column-gap)) / var(--width));
  padding-left: calc((var(--columnWidth) * var(--before)) + (var(--column-gap) * var(--before)));
  margin-top: calc(var(--spacing-unit) * var(--top) * 4); }

.project__tags {
  margin-top: calc(var(--spacing-unit) * 8);
  grid-column: 2 / -2;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: calc(var(--spacing-unit) * 2);
  align-items: center; }
  .project__tags::after {
    content: "";
    border-bottom: 1px solid var(--primary-color);
    opacity: 0.4; }
  .project__tags .tags {
    flex-wrap: nowrap; }

.project__cta-banner {
  grid-column: 2 / -2; }

.project__deepdive-teaser {
  margin-top: calc(var(--spacing-unit) * 20);
  grid-column: 2 / -2; }

@media (max-width: 900px) {
  .project__content {
    row-gap: calc(var(--spacing-unit) * 14); }
  .content-block {
    grid-column: 1 / -1;
    padding-left: 0;
    margin-top: 0; }
  .project__tags .tags {
    flex-wrap: wrap; } }

@media (max-width: 599px) {
  .project__intro {
    margin-top: calc(var(--spacing-unit) * 17); }
  .project__subtitle {
    margin-top: calc(var(--spacing-unit) * 2); }
  .project__content {
    margin-top: calc(var(--spacing-unit) * 10);
    row-gap: calc(var(--spacing-unit) * 10); }
  .project__tags {
    display: none; }
  .project__deepdive-teaser {
    margin-top: calc(var(--spacing-unit) * 10); } }

.sketchbook__intro {
  margin-top: calc(var(--spacing-unit) * 25);
  grid-column: 2 / -2;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  color: var(--primary-color);
  max-width: 20em; }
  @media (max-width: 599px) {
    .sketchbook__intro {
      font-size: 23px;
      line-height: 30px; } }
  .sketchbook__intro a {
    color: var(--secondary-color);
    text-decoration: underline;
    text-decoration-color: var(--secondary-color);
    text-decoration-skip-ink: auto;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px; }

.sketchbook__content {
  margin-top: calc(var(--spacing-unit) * 15);
  grid-column: 2 / -2;
  min-height: 80vh;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: calc(var(--spacing-unit) * 4);
  row-gap: calc(var(--spacing-unit) * 4); }

.sketchbook__loading {
  display: none; }

.sketchbook__loading:only-child {
  display: block;
  grid-column: span 12;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  color: var(--gray-500); }
  @media (max-width: 599px) {
    .sketchbook__loading:only-child {
      font-size: 23px;
      line-height: 30px; } }

.sketchbook__image {
  grid-column: span 4;
  display: block;
  background-color: var(--gray-200);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0px;
  padding-top: 100%; }

.sketchbook__image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  padding: 0;
  mix-blend-mode: multiply;
  object-fit: cover; }

.sketchbook__image-link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: calc(var(--spacing-unit) * 2);
  padding-top: calc(var(--spacing-unit) * 8);
  color: var(--white);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  opacity: 0;
  transform: translateY(10%);
  transition: all 0.3s;
  text-decoration: underline;
  text-decoration-color: var(--white);
  text-decoration-skip-ink: auto;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px; }

.sketchbook__image:hover .sketchbook__image-link {
  opacity: 1;
  transform: translateY(0%); }

.sketchbook__outro {
  margin-top: calc(var(--spacing-unit) * 15);
  grid-column: 2 / -2;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  color: var(--primary-color);
  max-width: 21em; }
  @media (max-width: 599px) {
    .sketchbook__outro {
      font-size: 23px;
      line-height: 30px; } }
  .sketchbook__outro a {
    color: var(--secondary-color);
    text-decoration: underline;
    text-decoration-color: var(--secondary-color);
    text-decoration-skip-ink: auto;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px; }

@media (max-width: 900px) {
  .sketchbook__image {
    grid-column: span 6; } }

@media (max-width: 599px) {
  .sketchbook__intro {
    margin-top: calc(var(--spacing-unit) * 17);
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400; }
    .sketchbook__intro a {
      white-space: nowrap; }
  .sketchbook__content {
    margin-top: calc(var(--spacing-unit) * 8);
    column-gap: calc(var(--spacing-unit) * 2);
    row-gap: calc(var(--spacing-unit) * 2); }
  .sketchbook__outro {
    margin-top: calc(var(--spacing-unit) * 8);
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 400; } }
  @media (max-width: 599px) and (max-width: 599px) {
    .sketchbook__outro {
      font-size: 21px;
      line-height: 29px; } }

.travel-journal-trip {
  background-color: var(--deepdive-blue-dark); }

.travel-journal-trip__header {
  margin-top: calc(var(--spacing-unit) * 26);
  margin-bottom: calc(var(--spacing-unit) * 22);
  grid-column: 2 / 10;
  grid-row: 1; }

.travel-journal-trip__title {
  font-size: 110px;
  line-height: 105px;
  letter-spacing: -0.03em;
  font-weight: 300;
  hyphens: manual;
  color: var(--primary-color); }

.travel-journal-trip__intro {
  color: var(--white);
  margin-top: calc(var(--spacing-unit) * 4);
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  max-width: 33em;
  opacity: 1; }
  @media (max-width: 599px) {
    .travel-journal-trip__intro {
      font-size: 21px;
      line-height: 29px; } }
  @media (max-width: 599px) {
    .travel-journal-trip__intro {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.007em;
      font-weight: 400; } }

.travel-journal-trip__intro-separator {
  opacity: 0.4; }

.travel-journal-trip__left {
  grid-column: 2 / 10;
  grid-row: 2; }

.travel-journal-trip__cards {
  container-type: inline-size;
  container-name: card-container; }

.travel-journal-trip__loading {
  margin-top: calc(var(--spacing-unit) * 15);
  margin-bottom: calc(var(--spacing-unit) * 0);
  color: white;
  grid-column: 2 / -2;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s; }
  .travel-journal-trip__loading--is-loading {
    animation: pulsate 1.5s ease-out;
    animation-iteration-count: infinite; }

@keyframes pulsate {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

.travel-journal-trip__right-content {
  grid-column: 10 / -1;
  grid-row: 1 / 3;
  padding-top: 100px;
  height: 100%;
  position: relative; }

.travel-journal-trip__map {
  border-radius: 20px;
  position: sticky !important;
  top: 70px;
  height: calc(100vh - 100px);
  width: calc(100% - 60px);
  margin-left: 30px; }

@media (max-width: 1200px) {
  .travel-journal-trip__header {
    grid-column: 2 / -2; }
  .travel-journal-trip__left {
    grid-column: 2 / -2; }
  .travel-journal-trip__right-content {
    display: none; } }

@media (max-width: 900px) {
  .travel-journal-trip__header {
    margin-top: calc(var(--spacing-unit) * 26);
    margin-bottom: calc(var(--spacing-unit) * 22);
    grid-column: 2 / -2; } }

@media (max-width: 599px) {
  .travel-journal-trip__header {
    margin-top: calc(var(--spacing-unit) * 20);
    margin-bottom: calc(var(--spacing-unit) * 16); }
  .travel-journal-trip__title {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.03em;
    font-weight: 300;
    hyphens: manual; }
  .travel-journal-trip__intro {
    margin-top: calc(var(--spacing-unit) * 8); } }

.travel-journal-entry {
  background-color: var(--deepdive-blue-dark); }

.travel-journal-entry__main {
  background: linear-gradient(var(--deepdive-blue-dark) calc(100% - calc(var(--spacing-unit) * 160)), var(--deepdive-blue));
  color: var(--entry-color-dark);
  max-width: 100vw;
  overflow-x: clip; }

.travel-journal-entry__background {
  background-color: var(--entry-color-light);
  grid-column: 9 / -1;
  grid-row: 1 / -1; }

.travel-journal-entry__left {
  margin-top: calc(var(--spacing-unit) * 24);
  padding-bottom: calc(var(--spacing-unit) * 34);
  padding-right: calc(var(--spacing-unit) * 6);
  grid-column: 2 / 9;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: end; }

.travel-journal-entry__left-content {
  position: sticky;
  bottom: 0;
  padding-bottom: calc(var(--spacing-unit) * 6);
  min-height: 100vh; }

.travel-journal-entry__title {
  display: block;
  text-decoration: none;
  margin-bottom: calc(var(--spacing-unit) * 12);
  grid-area: title;
  color: var(--primary-color); }
  .travel-journal-entry__title h1 {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -0.015em;
    font-weight: 600;
    line-height: 1.1em; }
    @media (max-width: 599px) {
      .travel-journal-entry__title h1 {
        font-size: 36px;
        line-height: 43px; } }

.travel-journal-entry__location-title {
  white-space: nowrap;
  hyphens: manual; }

.travel-journal-entry__location-title-text {
  white-space: normal; }

.travel-journal-entry__date {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.004em;
  font-weight: 300;
  opacity: 0.7;
  margin-top: 0.1em; }
  @media (max-width: 599px) {
    .travel-journal-entry__date {
      font-size: 23px;
      line-height: 30px; } }

.travel-journal-entry__tags {
  display: inline-block;
  background-color: var(--entry-color-dark);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1em;
  border-radius: calc(var(--spacing-unit) * 1);
  padding: 0.4em 0.5em;
  opacity: 1;
  vertical-align: 0.7em; }

.travel-journal-entry__images {
  display: grid;
  grid-template-columns: repeat(16, 1fr); }
  .travel-journal-entry__images .travel-journal-entry__image:nth-child(3n + 1) {
    grid-column: 1 / -3;
    z-index: 1; }
  .travel-journal-entry__images .travel-journal-entry__image:nth-child(3n + 2) {
    grid-column: 3 / -1; }
  .travel-journal-entry__images .travel-journal-entry__image:nth-child(3n + 3) {
    grid-column: 2 / -2;
    z-index: 1; }

.travel-journal-entry__image {
  display: block;
  margin-bottom: calc(var(--spacing-unit) * -5); }
  .travel-journal-entry__image:last-child {
    margin-bottom: 0; }

.travel-journal-entry__image img,
.travel-journal-entry__image video {
  border-radius: calc(var(--spacing-unit) * 2);
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; }

.travel-journal-entry__right {
  grid-column: 9 / -2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: end;
  height: 100%; }

.travel-journal-entry__right-content {
  padding-left: calc(var(--spacing-unit) * 6);
  padding-bottom: calc(var(--spacing-unit) * 6);
  position: sticky;
  bottom: 0;
  width: 100%;
  min-height: 100vh; }

.travel-journal-entry__map {
  height: 420px;
  margin-left: calc(var(--spacing-unit) * -6);
  margin-right: calc(var(--spacing-unit) * -8); }
  @media (min-width: 1440px) {
    .travel-journal-entry__map {
      margin-right: calc((100vw - 1344px) / 2 * -1); } }
  @media (max-width: 900px) {
    .travel-journal-entry__map {
      margin-right: calc(var(--spacing-unit) * -9);
      margin-left: calc(var(--spacing-unit) * -9); } }
  @media (max-width: 599px) {
    .travel-journal-entry__map {
      margin-right: calc(var(--spacing-unit) * -3);
      margin-left: calc(var(--spacing-unit) * -3); } }

.travel-journal-entry__meta {
  max-width: unset;
  column-gap: calc(var(--spacing-unit) * 2);
  row-gap: calc(var(--spacing-unit) * 0.75);
  border-radius: 0;
  margin-top: calc(var(--spacing-unit) * -5);
  margin-left: calc(var(--spacing-unit) * -6);
  padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 6);
  z-index: 2;
  position: relative;
  margin-right: calc(var(--spacing-unit) * -8); }
  @media (min-width: 1440px) {
    .travel-journal-entry__meta {
      margin-right: calc((100vw - 1344px) / 2 * -1); } }
  @media (max-width: 900px) {
    .travel-journal-entry__meta {
      margin-right: calc(var(--spacing-unit) * -9);
      margin-left: calc(var(--spacing-unit) * -9);
      padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 9); } }
  @media (max-width: 599px) {
    .travel-journal-entry__meta {
      margin-right: calc(var(--spacing-unit) * -3);
      margin-left: calc(var(--spacing-unit) * -3);
      padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 3); } }

.travel-journal-entry__text {
  margin-top: calc(var(--spacing-unit) * 6); }
  .travel-journal-entry__text p {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.007em;
    font-weight: 400;
    opacity: 0.8;
    max-width: 32em; }
  .travel-journal-entry__text p:first-child {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 400;
    margin-bottom: calc(var(--spacing-unit) * 5);
    max-width: 30em; }
    @media (max-width: 599px) {
      .travel-journal-entry__text p:first-child {
        font-size: 21px;
        line-height: 29px; } }
    .travel-journal-entry__text p:first-child:last-child {
      margin-bottom: 0; }

.travel-journal-entry__footer {
  margin-top: calc(var(--spacing-unit) * 8);
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--spacing-unit) * 2); }

.travel-journal-entry__all-link {
  margin-top: calc(var(--spacing-unit) * 14);
  margin-left: calc(var(--spacing-unit) * 6);
  margin-bottom: calc(var(--spacing-unit) * 12);
  display: block;
  opacity: 0.5;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.007em;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-decoration-color: currentColor;
  transition: all 0.6s; }
  .travel-journal-entry__all-link .arrow-right {
    transform: translateX(0);
    transition: all 0.3s;
    margin-left: calc(var(--spacing-unit) * 0.66);
    opacity: 0.5; }
  @media (hover: hover) {
    .travel-journal-entry__all-link:hover {
      text-decoration-color: currentColor;
      opacity: 1; }
      .travel-journal-entry__all-link:hover .arrow-right {
        transform: translateX(calc(var(--spacing-unit) * 1)); } }

.travel-journal-entry__pagination-content {
  grid-column: 2 /-2;
  margin-top: calc(var(--spacing-unit) * -6);
  margin-bottom: calc(var(--spacing-unit) * -16);
  color: white;
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: calc(var(--spacing-unit) * 6); }

@media (max-width: 1200px) {
  .travel-journal-entry__left {
    grid-column: 2 / 8;
    padding-right: calc(var(--spacing-unit) * 3); }
  .travel-journal-entry__right {
    grid-column: 8 / -2; }
  .travel-journal-entry__background {
    grid-column: 8 / -1; }
  .travel-journal-entry__pagination-content > *:nth-child(1) {
    display: none; } }

@media (max-width: 1050px) {
  .travel-journal-entry__pagination-content > *:nth-last-child(1) {
    display: none; } }

@media (max-width: 900px) {
  .travel-journal-entry__left {
    margin-top: calc(var(--spacing-unit) * 20);
    padding-bottom: calc(var(--spacing-unit) * 10);
    padding-right: 0;
    grid-column: 2 / -2;
    grid-row: 1; }
  .travel-journal-entry__left-content {
    position: relative;
    top: unset; }
  .travel-journal-entry__right {
    grid-column: 2 / -2;
    grid-row: 2; }
  .travel-journal-entry__right-content {
    padding-left: 0;
    padding-bottom: calc(var(--spacing-unit) * 6);
    position: relative;
    bottom: unset;
    min-height: auto; }
  .travel-journal-entry__text {
    margin-top: calc(var(--spacing-unit) * 10); }
  .travel-journal-entry__all-link {
    margin-top: calc(var(--spacing-unit) * 10);
    margin-left: 0;
    margin-bottom: calc(var(--spacing-unit) * 12); }
  .travel-journal-entry__background {
    grid-column: 1 / -1;
    grid-row: 2; }
  .travel-journal-entry__pagination-content {
    margin-bottom: calc(var(--spacing-unit) * -10); } }

@media (max-width: 599px) {
  .travel-journal-entry__left {
    margin-top: calc(var(--spacing-unit) * 16);
    padding-bottom: calc(var(--spacing-unit) * 6); }
  .travel-journal-entry__title {
    margin-bottom: calc(var(--spacing-unit) * 8); }
  .travel-journal-entry__pagination-content {
    grid-column: 2 /-2;
    margin-top: calc(var(--spacing-unit) * -6);
    margin-bottom: calc(var(--spacing-unit) * -6);
    color: white;
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    gap: calc(var(--spacing-unit) * 6); }
  .travel-journal-entry__map {
    height: 320px; }
  .travel-journal-entry__text {
    margin-top: calc(var(--spacing-unit) * 6); } }

.travel-journal {
  background-color: var(--deepdive-blue-dark);
  transition: background-color 1s; }

.travel-journal__header {
  margin-top: calc(var(--spacing-unit) * 26);
  margin-bottom: calc(var(--spacing-unit) * 3);
  grid-column: 4 / -4; }

.travel-journal__title {
  font-size: 110px;
  line-height: 105px;
  letter-spacing: -0.03em;
  font-weight: 300;
  hyphens: manual;
  color: var(--primary-color); }

.travel-journal__intro {
  color: var(--white);
  margin-top: calc(var(--spacing-unit) * 10); }
  .travel-journal__intro p {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    font-weight: 400;
    max-width: 33em;
    opacity: 0.7; }
    @media (max-width: 599px) {
      .travel-journal__intro p {
        font-size: 21px;
        line-height: 29px; } }
    @media (max-width: 599px) {
      .travel-journal__intro p {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.007em;
        font-weight: 400; } }
  .travel-journal__intro p:first-child {
    opacity: 1;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.004em;
    font-weight: 300;
    max-width: 24em; }
    @media (max-width: 599px) {
      .travel-journal__intro p:first-child {
        font-size: 23px;
        line-height: 30px; } }

.travel-journal__trip-cards {
  margin-top: calc(var(--spacing-unit) * 12);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: calc(var(--spacing-unit) * 4); }

.travel-journal__trip-card {
  color: var(--deepdive-blue-dark);
  background-color: var(--primary-color);
  border-radius: calc(var(--spacing-unit) * 2);
  padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 2.5);
  display: block;
  text-decoration: none;
  align-self: start; }

.travel-journal__trip-card-title {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400; }
  @media (max-width: 599px) {
    .travel-journal__trip-card-title {
      font-size: 21px;
      line-height: 29px; } }

.travel-journal__trip-card-intro {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-weight: 400;
  opacity: 0.7; }

.travel-journal__trip-card-intro-separator {
  opacity: 0.3; }

.travel-journal__trip-title {
  grid-column: 2 / -2;
  margin-top: calc(var(--spacing-unit) * 25);
  margin-bottom: calc(var(--spacing-unit) * 15);
  color: var(--primary-color);
  font-size: 110px;
  line-height: 110px;
  letter-spacing: -0.03em;
  font-weight: 300;
  font-size: 80px;
  text-align: center; }
  @media (max-width: 900px) {
    .travel-journal__trip-title {
      font-size: 86px;
      line-height: 90px; } }
  @media (max-width: 599px) {
    .travel-journal__trip-title {
      font-size: 54px;
      line-height: 58px; } }
  @media (max-width: 599px) {
    .travel-journal__trip-title {
      margin-top: calc(var(--spacing-unit) * 15); } }

.travel-journal__trip-date {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.9);
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  line-height: 1em;
  border-radius: calc(var(--spacing-unit) * 1);
  padding: 0.3em 0.4em;
  opacity: 1;
  vertical-align: 0.7em;
  margin-left: 1.1em; }
  @media (max-width: 599px) {
    .travel-journal__trip-date {
      font-size: 21px;
      line-height: 29px; } }

.travel-journal-entry-card + .travel-journal-entry-card {
  margin-top: calc(var(--spacing-unit) * 25); }
  @media (max-width: 599px) {
    .travel-journal-entry-card + .travel-journal-entry-card {
      margin-top: calc(var(--spacing-unit) * 15); } }

.travel-journal__cards {
  grid-column: 1 / -1;
  container-type: inline-size;
  container-name: card-container; }

.travel-journal__loading {
  margin-top: calc(var(--spacing-unit) * 25);
  margin-bottom: calc(var(--spacing-unit) * 0);
  color: white;
  grid-column: 2 / -2;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s; }
  .travel-journal__loading--is-loading {
    animation: pulsate 1.5s ease-out;
    animation-iteration-count: infinite; }

@keyframes pulsate {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

@media (max-width: 1200px) {
  .travel-journal__header {
    grid-column: 3 / -3; } }

@media (max-width: 900px) {
  .travel-journal__header {
    margin-top: calc(var(--spacing-unit) * 26);
    margin-bottom: calc(var(--spacing-unit) * 22);
    grid-column: 2 / -2; } }

@media (max-width: 599px) {
  .travel-journal__header {
    margin-top: calc(var(--spacing-unit) * 20);
    margin-bottom: calc(var(--spacing-unit) * 16); }
  .travel-journal__title {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.03em;
    font-weight: 300;
    hyphens: manual; }
  .travel-journal__intro {
    margin-top: calc(var(--spacing-unit) * 8); } }

@font-face {
  font-family: "HK Grotesk";
  src: url("../../fonts/HKGrotesk-Light.woff2") format("woff2"), url("../../fonts/HKGrotesk-Light.woff") format("woff");
  font-weight: 300;
  font-display: swap; }

@font-face {
  font-family: "HK Grotesk";
  src: url("../../fonts/HKGrotesk-Regular.woff2") format("woff2"), url("../../fonts/HKGrotesk-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap; }

@font-face {
  font-family: "HK Grotesk";
  src: url("../../fonts/HKGrotesk-SemiBold.woff2") format("woff2"), url("../../fonts/HKGrotesk-SemiBold.woff2") format("woff");
  font-weight: 600;
  font-display: swap; }

@font-face {
  font-family: "HK Grotesk";
  src: url("../../fonts/HKGrotesk-Bold.woff2") format("woff2"), url("../../fonts/HKGrotesk-Bold.woff2") format("woff");
  font-weight: 700;
  font-display: swap; }

*,
*::after,
*::before {
  box-sizing: border-box; }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  font-family: var(--font-family-default);
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.007em;
  font-weight: 400;
  hyphens: auto;
  -webkit-hyphens: auto; }

strong {
  font-weight: var(--font-weight-medium); }

h1,
h2,
h3,
h4,
ul {
  margin: 0;
  padding: 0; }

p {
  margin: 0;
  margin-bottom: 0.6em;
  max-width: 36em; }
  p:last-child {
    margin-bottom: 0; }

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: var(--gray-400);
  text-decoration-skip-ink: auto;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  transition: all 0.3s; }
  a:hover {
    text-decoration-color: var(--primary-color); }
